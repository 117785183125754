import { css, theme } from "../../../../theme";
export const sidebarItemStyle = css({
  padding: "10px 18px",
  borderRadius: "6px",
  fontFamily: theme.font.bodyFontFamily,
  fontStyle: "normal",
  fontWeight: theme.fontWeights.bodyTextWeight,
  fontSize: theme.fontSizes.caption,
  lineHeight: "20px",
  marginBottom: 4,
  cursor: "pointer",
  transition: "0.2s background ease",
  "&:hover": {
    background: theme.color.picklerickGreen100,
    color: theme.color.white
  },
  variants: {
    status: {
      common: {
        color: theme.color.squanchyGray400
      },
      selected: {
        background: theme.color.picklerickGreen100,
        color: theme.color.white
      }
    }
  },
  defaultVariants: {
    status: "common"
  }
});