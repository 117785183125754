import { css, theme } from "../../../theme";
export const detailsHeadStyle = css({
  width: "100%",
  marginBottom: theme.space.medium,
  "& .card": {
    width: "100%"
  },
  "& .card-content": {
    alignItems: "stretch",
    display: "flex",
    justifyContent: "space-between"
  },
  "& .item": {
    display: "inline-flex",
    flexDirection: "column",
    justifyContent: "space-between",
    "&:not(:first-of-type)": {
      marginLeft: theme.space.medium
    },
    "& > span": {
      color: theme.color.squanchyGray300,
      fontSize: theme.fontSizes.body,
      "&:first-letter": {
        textTransform: "capitalize"
      }
    },
    "& > div": {
      alignItems: "center",
      display: "flex",
      height: 30
    },
    "& .identifier": {
      color: theme.color.birdpersonGray500,
      fontSize: theme.fontSizes.body,
      fontWeight: theme.fontWeights.bold,
      lineHeight: theme.space.medium,
      margin: 0,
      maxWidth: 300
    }
  },
  "& .property": {
    color: theme.color.birdpersonGray500,
    fontSize: theme.fontSizes.body,
    fontWeight: theme.fontWeights.bold,
    lineHeight: theme.space.medium,
    "&:empty": {
      borderTop: "1px solid ".concat(theme.color.squanchyGray100),
      marginTop: theme.space.small
    }
  },
  "& .actions": {
    alignItems: "center",
    display: "flex",
    "& > button:not(:first-of-type)": {
      marginLeft: theme.space.medium
    }
  },
  "@media print": {
    alignItems: "baseline",
    borderBottom: "1px solid ".concat(theme.color.squanchyGray50),
    padding: "".concat(theme.space.medium, " 0"),
    width: "100%",
    "& .card": {
      width: "100%"
    },
    "& .card-content": {
      justifyContent: "center"
    },
    "& .actions": {
      display: "none"
    },
    "& > div:first-child": {
      display: "flex",
      justifyContent: "space-between",
      width: "100%"
    },
    "& .item": {
      height: 40,
      "& .identifier": {
        alignItems: "baseline",
        fontSize: theme.space.medium,
        height: "auto",
        justifyContent: "center"
      },
      "& > span": {
        color: theme.color.black,
        flexWrap: "wrap",
        fontSize: theme.fontSizes.body,
        fontWeight: theme.fontWeights.normal,
        textAlign: "center",
        maxWidth: 150
      },
      "& .propertyChildren": {
        fontSize: theme.fontSizes.small,
        fontWeight: theme.fontWeights.bold,
        height: "auto",
        justifyContent: "center",
        "& abbr": {
          background: "none",
          fontSize: theme.fontSizes.small,
          padding: 0,
          textTransform: "initial"
        },
        "& strong": {
          fontSize: theme.fontSizes.small
        }
      }
    }
  }
});