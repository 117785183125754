const handlePDFExportDownloadingClick = (data, filename) => {
  /* eslint-disable no-undef */
  const downloadLink = document.createElement('a')
  downloadLink.target = '_blank'
  downloadLink.download = filename.replace(/[^A-Z0-9 -]+/ig, ' ').trim()

  const blob = new Blob([data], { type: 'application/pdf' })

  const URL = window.URL || window.webkitURL
  const downloadUrl = URL.createObjectURL(blob)

  downloadLink.href = downloadUrl

  document.body.append(downloadLink)

  downloadLink.click()

  document.body.removeChild(downloadLink)
  URL.revokeObjectURL(downloadUrl)
  /* eslint-enable no-undef */
}

const exportPDF = (res, filename) => {
  handlePDFExportDownloadingClick(res, filename)
}

export default exportPDF
