import React from 'react'
import PropTypes from 'prop-types'
import { HashRouter, Link } from 'react-router-dom'
import {
  Button,
  Card,
  CardContent,
  Flexbox,
} from 'former-kit'
import styles from './style.css'

const InfoCard = ({
  buttonLabel,
  description,
  disabled,
  eventTrack,
  isInternalLink,
  link,
  onClick,
  title,
}) => {
  const dataTestId = link && `button${link.toLowerCase().replace(/\//g, '-')}`

  const getAction = () => {
    if (onClick) {
      return (
        <Button
          disabled={disabled}
          data-testid={dataTestId}
          onClick={() => {
            onClick()
            if (eventTrack) {
              eventTrack()
            }
          }}
        >
          {buttonLabel}
        </Button>
      )
    }
    if (isInternalLink) {
      return (
        <HashRouter>
          <Link to={!disabled && link} className={styles.link}>
            <Button
              disabled={disabled}
              data-testid={dataTestId}
              onClick={() => eventTrack && eventTrack()}
            >
              {buttonLabel}
            </Button>
          </Link>
        </HashRouter>
      )
    }

    return (
      <a
        className={styles.link}
        href={!disabled && link}
        target="_blank"
        rel="noreferrer noopener"
      >
        <Button
          disabled={disabled}
          data-testid={dataTestId}
          onClick={() => eventTrack && eventTrack()}
        >
          {buttonLabel}
        </Button>
      </a>
    )
  }
  return (
    <Card>
      <CardContent>
        <Flexbox
          alignItems="center"
          className={styles.infoCard}
          justifyContent="space-between"
        >
          <div>
            <h3 className={styles.title}>{title}</h3>
            <div className={styles.description}>
              {description}
            </div>
          </div>
          {getAction()}
        </Flexbox>
      </CardContent>
    </Card>
  )
}

InfoCard.defaultProps = {
  disabled: false,
  eventTrack: null,
  isInternalLink: false,
  onClick: null,
}

InfoCard.propTypes = {
  buttonLabel: PropTypes.string.isRequired,
  description: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  eventTrack: PropTypes.func,
  isInternalLink: PropTypes.bool,
  link: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  title: PropTypes.string.isRequired,
}

export default InfoCard
