import {
  isNil, not, either, equals,
} from 'ramda'
import getOtpErrorMessage from '../../models/otp/buildErrorsMessage'

const PRECONDITION_FAILED = 412
const TO_MANY_REQUEST = 429

const isOtpError = either(
  equals(PRECONDITION_FAILED),
  equals(TO_MANY_REQUEST)
)

function preconditionFailed (dataOtp, openOtp, t) {
  return {
    status: isNil(dataOtp) ? 'success' : 'error',
    statusMessage: getOtpErrorMessage(t, {
      isFirstTime: not(openOtp),
      isResendCode: openOtp && isNil(dataOtp),
    }),
  }
}

function toManyRequest (t) {
  return {
    status: 'error',
    statusMessage: t('components.otp.too_many_requests'),
  }
}

function defaultMessage (t) {
  return {
    status: 'error',
    statusMessage: t('components.otp.default'),
  }
}

function OTPValidation (status, dataOtp, openOtp, t) {
  if (!isOtpError(status)) return null

  const validationObject = {
    [PRECONDITION_FAILED]:
        preconditionFailed(dataOtp, openOtp, t),
    [TO_MANY_REQUEST]: toManyRequest(t),
  }

  return validationObject[status] ?? defaultMessage(t)
}

export default OTPValidation
