import buildErrorsMessage from './buildErrorsMessage'

const actions = {
  ...buildErrorsMessage('refuse.action.remake_transaction',
    [5003, 5006, 5054, 5089, 5095, 5097,
      9108, 9109, 9111, 9999, 'internal_error']),
  acquirer_timeout: 'refuse.action.acquirer_timeout',
  antifraud: 'refuse.action.antifraud',
  capture_timeout: 'refuse.action.capture_timeout',
  fraud_reimbursed: 'fraud_reimbursed.action',
  invalid_capture_amount: 'refuse.action.invalid_capture_amount',
  manual_review: 'refuse.action.manual_review',
  manual_review_timeout: 'refuse.action.manual_review_timeout',
  no_acquirer: 'refuse.action.no_acquirer',
  unknown: 'refuse.action.unknown_error',
}

export default actions
