import { css, theme } from "../../../theme";
export const propertyStyle = css({
  display: "block",
  maxWidth: "100%",
  "& .title": {
    color: theme.color.squanchyGray400,
    fontWeight: theme.fontWeights.normal,
    margin: "0 0 4px",
    fontSize: "12px"
  },
  "& .value": {
    color: theme.color.squanchyGray400,
    fontSize: theme.fontSizes.small,
    display: "block"
  },
  "@media print": {
    display: "flex",
    justifyContent: "space-between",
    "& .value": {
      fontSize: theme.fontSizes.small
    },
    "& .title": {
      fontSize: theme.fontSizes.body,
      fontWeight: theme.fontWeights.bold,
      "&:after": {
        content: ": ",
        paddingRight: 3
      }
    }
  }
});