import React from 'react';
import { FormBox } from '../../../components';
import { inputDateStyle } from './InputDate.style';
export const InputDate = _ref => {
  let {
    value,
    status,
    onChange,
    classes
  } = _ref;
  const onClickDisable = e => e.preventDefault();
  const inputStatus = status ? status : "default";
  return React.createElement(FormBox, {
    className: classes === null || classes === void 0 ? void 0 : classes.formBox
  }, React.createElement("input", {
    onClick: onClickDisable,
    type: 'date',
    onChange: onChange,
    value: value,
    className: "\n          ".concat(inputDateStyle({
      status: inputStatus
    }), "\n          ").concat(classes === null || classes === void 0 ? void 0 : classes.input, "\n        ")
  }));
};