import React from 'react'
import PropTypes from 'prop-types'

import formatCurrency from '../../formatters/currency'

import style from './style.css'

const Subscription = ({
  amount,
  plan_id: planId,
  t,
  tPrefix,
}) => (
  <div className={style.subcard}>
    <h3 className={style.subtitle}>{t(`${tPrefix}.subscription.title`)}</h3>
    <div className={style.info}>
      <p>{t(`${tPrefix}.subscription.id`)}</p>
      <p>{planId}</p>
    </div>
    <div className={style.info}>
      <p>{t(`${tPrefix}.subscription.amount`)}</p>
      <p>{formatCurrency(amount)}</p>
    </div>
  </div>
)

Subscription.propTypes = {
  amount: PropTypes.number.isRequired,
  plan_id: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
  tPrefix: PropTypes.string.isRequired,
}

export default Subscription
