/* eslint-disable sort-keys */
const status = {
  'Aguardando Estorno': {
    color: '#3f2787',
    text: 'Aguardando Estorno',
  },
  'Aguardando Pagamento': {
    color: '#f3b700',
    text: 'Aguardando pgto',
  },
  Autorizado: {
    color: '#f8cc43',
    text: 'Autorizado',
    textColor: '#070817',
  },
  awaiting_response: {
    color: '#E0EBFD',
    text: 'Processando',
    textColor: '#1B49A0',
  },
  canceled: {
    color: '#f55d8b',
    text: 'Cancelada',
    textColor: '#070817',
  },
  cancelled: {
    color: '#f55d8b',
    text: 'Cancelada',
    textColor: '#070817',
  },
  Cancelado: {
    color: '#070817',
    text: 'pages.subscriptions.status.canceled',
  },
  chargeback: {
    color: '#070817',
    text: 'Chargeback',
  },
  Chargeback: {
    color: '#070817',
    text: 'Chargeback',
  },
  chargedback_refund: {
    color: '#a71a44',
    text: 'Cbk reapresentado',
  },
  confirmed: {
    color: '#65a300',
    text: 'Confirmado',
  },
  'Em análise': {
    color: '#235894',
    text: 'Em análise',
  },
  trialing: {
    color: '#235894',
    text: 'Em Trial',
  },
  ended: {
    color: '#235894',
    text: 'Finalizado',
  },
  Estornado: {
    color: '#6045af',
    text: 'Estornado',
  },
  failed: {
    color: '#FCF0BA',
    text: 'Erro',
    textColor: '#1B2B4B',
  },
  fraud_reimbursed: {
    color: '#e2efff',
    text: 'Fraude Coberta',
    textColor: '#070817',
  },
  Pago: {
    color: '#65a300',
    text: 'Pago',
  },
  payment_scheduled: {
    color: '#E0EBFD',
    text: 'Processando',
    textColor: '#1B49A0',
  },
  pending: {
    color: '#E0EBFD',
    text: 'Processando',
    textColor: '#1B49A0',
  },
  prepaid: {
    color: '#65a300',
    text: 'Processando pagamento',
  },
  Processando: {
    color: '#5691d6',
    text: 'Processando',
  },
  Recusado: {
    color: '#df285f',
    text: 'Recusado',
  },
  refund: {
    color: '#6045af',
    text: 'Estornada',
  },
  'Revisão Pendente': {
    color: '#ec6e16',
    text: 'Revisão Pendente',
  },
  sent: {
    color: '#E0EBFD',
    text: 'Processando',
    textColor: '#1B49A0',
  },
  stalled: {
    color: '#E0EBFD',
    text: 'Processando',
    textColor: '#1B49A0',
  },
  success: {
    color: '#65a300',
    text: 'Paga',
  },
  suspended: {
    color: '#c25408',
    text: 'Suspensa',
  },
  timed_out: {
    color: '#5691d6',
    text: 'Aguardando Resposta',
  },
  unavailable: {
    color: '#72737a',
    text: 'Indisponível',
  },
  unpaid: {
    color: '#f55d8b',
    text: 'Não Pago',
  },
  waiting_funds: {
    color: '#f3b700',
    text: 'Aguardando pgto',
  },
  analyzing: {
    color: '#235894',
    text: 'Em análise',
  },
  authorized: {
    color: '#f8cc43',
    text: 'Autorizada',
    textColor: '#070817',
  },
  chargedback: {
    color: '#070817',
    text: 'Chargeback',
  },
  paid: {
    color: '#65a300',
    text: 'Paga',
  },
  pending_refund: {
    color: '#3f2787',
    text: 'Estorno pendente',
  },
  pending_review: {
    color: '#ec6e16',
    text: 'Revisão pendente',
  },
  processing: {
    color: '#5691d6',
    text: 'Processando',
  },
  refunded: {
    color: '#6045af',
    text: 'Estornada',
  },
  refused: {
    color: '#df285f',
    text: 'Recusada',
  },
  waiting_payment: {
    color: '#f3b700',
    text: 'Aguardando pgto',
  },
  pending_payment: {
    color: '#f3b700',
    text: 'Pgto Pendente',
  },
}

export default status
