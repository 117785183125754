import { css, theme } from "../../../../theme";
export const SearchInputWrapperStyle = css({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.space.tiny,
  paddingLeft: theme.space.tiny,
  paddingRight: theme.space.tiny,
  minHeight: "34px",
  boxSizing: 'border-box',
  flexWrap: 'wrap',
  variants: {
    status: {
      expanded: {
        borderBottom: "1px solid ".concat(theme.color.squanchyGray100)
      },
      collapsed: {
        borderBottom: 0
      }
    }
  },
  '.tags-wrapper': {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.space.tiny
  },
  svg: {
    width: '14px',
    height: '14px',
    color: theme.color.birdpersonGray500
  },
  input: {
    flexGrow: 1,
    height: '24px',
    border: 0,
    outline: 0,
    fontFamily: theme.font.bodyFontFamily,
    fontWeight: 400,
    fontSize: theme.fontSizes.body,
    lineHeight: 1.5,
    color: theme.color.birdpersonGray500,
    boxSizing: 'border-box'
  }
});