import { dropLast, last } from "ramda";
export const getStatus = truthy => {
  return truthy ? 'expanded' : 'collapsed';
};
export const filterOptionsThatPatternMatch = (text, options) => {
  return options.filter(option => !option.pattern || option.pattern.test(text));
};
export const buildOptionsSelected = (selecteds, options) => {
  return selecteds.map(item => Object.assign(Object.assign({}, options.find(option => option.key === item.key)), {
    value: item.value
  }));
};
export const getOptionsWhitoutSelecteds = (selecteds, options) => {
  const keysSelect = selecteds.map(item => item.key);
  return options.filter(option => !keysSelect.includes(option.key));
};
export const sliceWord = word => {
  return word.length > 16 ? "".concat(word.slice(0, 16), "...") : word;
};
export const buildPlaceholder = (options, selecteds, tFunc, tPlaceholderKey) => {
  const filteredOptions = getOptionsWhitoutSelecteds(selecteds, options);
  const prefix = tFunc(tPlaceholderKey || 'search_input.placeholder');
  const labels = filteredOptions.map(option => option.tag.replace(':', ''));
  const textOr = " ".concat(tFunc("search_input.or"), " ");
  if (labels.length <= 2) {
    return "".concat(prefix, " ").concat(labels.join(textOr), ".");
  }
  const lastLabel = last(labels);
  const labelsWhitoutLast = dropLast(1, labels);
  return "".concat(prefix, " ").concat(labelsWhitoutLast.join(', ')).concat(textOr).concat(lastLabel, ".");
};