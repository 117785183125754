import React from 'react'
import PropTypes from 'prop-types'
import { Button, Spacing } from 'former-kit'
import style from './style.css'
import BenchGuy from '../../assets/bench_guy.svg'

const tPrefix = 'components.conclusion_transaction_or_subscription'

const ConclusionTransactionOrSubscription = ({
  id,
  onCreateNew,
  onVisualize,
  t,
  type,
}) => {
  const conclusionType = type.toLowerCase()

  return (
    <div className={style.flex}>
      <BenchGuy data-testid="BenchGuy" />
      <h2 data-testid="conclusion" className={style.title}>
        {t(`${tPrefix}.${conclusionType}.created`, { id })}
      </h2>
      <div>
        <Button
          data-testid="button-visualize"
          fill="outline"
          onClick={onVisualize}
        >
          {t(`${tPrefix}.${conclusionType}.visualize`)}
        </Button>
        <Spacing size="small" />
        <Button
          onClick={onCreateNew}
        >
          {t(`${tPrefix}.${conclusionType}.create_new`)}
        </Button>
      </div>
    </div>
  )
}

ConclusionTransactionOrSubscription.propTypes = {
  id: PropTypes.number.isRequired,
  onCreateNew: PropTypes.func.isRequired,
  onVisualize: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
}

export default ConclusionTransactionOrSubscription
