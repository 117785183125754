import defaultBanks from '../../models/banks.json'
import { banksApiUrl } from '../../environment'

const formatBankOption = (bankOption) => {
  const bankCode = String(bankOption.code).padStart(3, '0')
  return {
    ispb: `${bankOption.ispb}`,
    name: `${bankCode} - ${bankOption.name}`,
    value: `${bankCode}`,
  }
}

export default async function fetchBanks () {
  try {
    const body = await fetch(banksApiUrl)
    const response = await body.json()
    const responseFormatted = response.map(bankOption => formatBankOption(bankOption)).filter(item => item.value !== 'null')
    responseFormatted.unshift({
      ispb: '00000000',
      name: '000 - Banco não informado',
      value: '000',
    })
    responseFormatted.sort((a, b) => a.value - b.value)
    return responseFormatted
  } catch (error) {
    return defaultBanks
  }
}

export const serializeBanks = bankOptions => bankOptions
  .reduce((banks, bank) => {
    // eslint-disable-next-line no-param-reassign
    banks[bank.value] = bank
    return banks
  }, {})
