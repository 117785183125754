import React, { useEffect, useState, useCallback } from 'react'
import {
  Button,
  DateSelector,
  isMomentPropValidation,
} from 'former-kit'
import ChevronDown32 from 'emblematic-icons/svg/ChevronDown32.svg'
import ChevronUp32 from 'emblematic-icons/svg/ChevronUp32.svg'
import PropTypes from 'prop-types'
import moment from 'moment'
import { includes } from 'ramda'

import dateSelectorPresets from '../../models/dateSelectorPresets'

const PopoverCalendar = ({
  calendarDates, datePresets, labelPlaceholder, maxDate, minDate, onChange, t,
}) => {
  const [showCalendar, setShowCalendar] = useState(false)
  const [rangeDates, setRangeDates] = useState({
    end: calendarDates.end || null,
    start: calendarDates.start || null,
  })
  const [rangeDatesPlaceholder, setRangeDatesPlaceholder] = useState('')

  useEffect(() => {
    setRangeDates({
      end: calendarDates.end || null,
      start: calendarDates.start || null,
    })
  }, [calendarDates])

  const possiblePresets = [7, 15, 30]

  const getSelectedPreset = ({ end, start }) => {
    if (end && start) {
      const today = moment()
      const daysDiff = Math.abs(start.diff(end, 'day'))
      if (today.isSame(start, 'day') && today.isSame(end, 'day')) {
        return 'today'
      }
      if (daysDiff <= 1) {
        return 'day'
      }
      if (includes(daysDiff, possiblePresets)) {
        return `days-${daysDiff}`
      }
    }
    return 'period'
  }

  const [selectedPreset, setSelectedPreset] = useState(
    getSelectedPreset(rangeDates)
  )

  const setPlaceholder = useCallback((dates) => {
    const startFormatted = dates.start && moment(dates.start).format('DD/MM/YYYY')
    const endFormatted = dates.end && moment(dates.end).format('DD/MM/YYYY')
    if (selectedPreset === 'single' || selectedPreset === 'today' || (startFormatted && !endFormatted)) {
      setRangeDatesPlaceholder(startFormatted)
      return
    }
    setRangeDatesPlaceholder(startFormatted || endFormatted
      ? `${startFormatted || ''} ${t('components.popoverCalendar.until')} ${endFormatted || ''}`
      : '')
  }, [selectedPreset, t])

  useEffect(() => {
    setPlaceholder(rangeDates)
  },
  [rangeDates, selectedPreset, setPlaceholder])

  const handleDateChange = (newRangeDates) => {
    setRangeDates(newRangeDates)
    onChange(newRangeDates)
  }

  const handlePresetChange = (_dates, preset) => {
    setSelectedPreset(preset.key)
  }

  const isEnabledDay = (date) => {
    if (minDate && maxDate) {
      return moment(date).isSameOrAfter(minDate, 'day') && moment(date).isSameOrBefore(moment(maxDate), 'day')
    }
    if (minDate) {
      return moment(date).isSameOrAfter(minDate, 'day')
    }
    if (maxDate) {
      return moment(date).isSameOrBefore(moment(maxDate), 'day')
    }

    return true
  }

  const buttonText = (labelPlaceholder && `${t(labelPlaceholder)}: ${rangeDatesPlaceholder}`)
    || rangeDatesPlaceholder

  return (
    <DateSelector
      dates={rangeDates}
      onConfirm={() => setShowCalendar(false)}
      onChange={handleDateChange}
      onPresetChange={handlePresetChange}
      placement="bottomStart"
      presets={(datePresets && datePresets(t)) || dateSelectorPresets(t)}
      selectedPreset={selectedPreset}
      showCalendar
      showSidebar
      visible={showCalendar}
      isValidDay={date => isEnabledDay(date)}
    >
      <Button
        relevance="low"
        fill="outline"
        iconAlignment="end"
        icon={showCalendar
          ? <ChevronUp32 width={16} height={16} />
          : <ChevronDown32 width={16} height={16} />
        }
        onClick={() => { setShowCalendar(!showCalendar) }}
      >
        {buttonText}
      </Button>
    </DateSelector>
  )
}

PopoverCalendar.propTypes = {
  calendarDates: PropTypes.shape({
    end: isMomentPropValidation,
    key: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    start: isMomentPropValidation,
  }).isRequired,
  datePresets: PropTypes.func,
  labelPlaceholder: PropTypes.string,
  maxDate: PropTypes.string,
  minDate: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
}

PopoverCalendar.defaultProps = {
  datePresets: null,
  labelPlaceholder: null,
  maxDate: null,
  minDate: null,
}

export default PopoverCalendar
