import React from 'react'
import { Legend } from 'former-kit'
import PropTypes from 'prop-types'

const tPrefix = 'pages.payments.status_legend'

const getPaymentStatus = t => ({
  created: {
    color: '#E0EBFD',
    label: t(`${tPrefix}.processing`),
    textColor: '#1B49A0',
  },
  failed: {
    color: '#DF285F',
    label: t(`${tPrefix}.failed`),
  },
  paid: {
    color: '#65A300',
    label: t(`${tPrefix}.paid`),
  },
  processing: {
    color: '#E0EBFD',
    label: t(`${tPrefix}.processing`),
    textColor: '#1B49A0',
  },
})

const PaymentStatusLegend = ({
  status,
  t,
}) => {
  const statusCodesMap = getPaymentStatus(t)
  const statusCodeLabel = statusCodesMap[status].label
  const statusCodeColor = statusCodesMap[status].color
  const statusCodeTextColor = statusCodesMap[status].textColor

  return (
    <Legend
      acronym={statusCodeLabel}
      color={statusCodeColor}
      textColor={statusCodeTextColor}
      hideLabel
    >
      {statusCodeLabel}
    </Legend>
  )
}

PaymentStatusLegend.propTypes = {
  status: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
}

export default PaymentStatusLegend
