import React, { useState } from 'react'
import { Alert, Button, FormInput } from 'former-kit'
import moment from 'moment'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import IconError from 'emblematic-icons/svg/CloseCircle32.svg'
import IconSuccess from 'emblematic-icons/svg/CheckCircle32.svg'

import style from './style.css'

const convertTime = time => (
  moment.utc(time).format('mm:ss')
)

const getIcon = status => (status === 'success'
  ? <IconSuccess width={16} height={16} />
  : <IconError width={16} height={16} />)

const OtpConfirmation = ({
  loading,
  onBack,
  onConfirm,
  onResendCode,
  status,
  statusMessage,
  t,
  time,
}) => {
  const [code, setCode] = useState('')

  const validateCode = code.length >= 6
  const withDelay = time > 0

  const submitForm = (e) => {
    e.preventDefault()
    onConfirm(code)
  }

  return (
    <form className={style.container} onSubmit={submitForm}>
      <h2 className={style.title}>
        {t('components.otp.title')}
      </h2>
      <p className={style.message}>
        {t('components.otp.message')}
      </p>
      {statusMessage && (
        <div className={style.alert}>
          <Alert
            icon={getIcon(status)}
            type={status}
          >
            <span>{statusMessage}</span>
          </Alert>
        </div>
      )}
      <FormInput
        label={t('components.otp.input')}
        mask="111111"
        name="otp"
        type="text"
        inputMode="numeric"
        value={code}
        onChange={event => setCode(event.target.value)}
      />
      <button
        disabled={loading || withDelay}
        onClick={onResendCode}
        type="button"
        className={style.resendCode}
      >
        {!withDelay
          ? t('components.otp.resend_code')
          : `${t('components.otp.resend_code_in')} ${convertTime(time)}`
        }
      </button>

      <div className={
        classnames(style.footer, onBack && style.onBack)}
      >
        {onBack && (
          <Button
            disabled={loading}
            fill="outline"
            onClick={onBack}
          >
            {t('components.otp.back')}
          </Button>
        )}
        <Button
          disabled={!validateCode || loading}
          type="submit"
        >
          {t('components.otp.confirm')}
        </Button>
      </div>
    </form>
  )
}

export default OtpConfirmation

OtpConfirmation.propTypes = {
  loading: PropTypes.bool,
  onBack: PropTypes.func,
  onConfirm: PropTypes.func.isRequired,
  onResendCode: PropTypes.func.isRequired,
  status: PropTypes.oneOf([
    'error',
    'success',
  ]),
  statusMessage: PropTypes.string,
  t: PropTypes.func.isRequired,
  time: PropTypes.number,
}

OtpConfirmation.defaultProps = {
  loading: false,
  onBack: null,
  status: 'success',
  statusMessage: '',
  time: 0,
}
