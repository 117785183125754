import React from 'react'
import PropTypes from 'prop-types'

import {
  Card,
  CardContent,
  CardTitle,
  Grid,
  Row,
  Col,
} from 'former-kit'

import Property from '../Property'
import style from './style.css'
import currencyFormatter from '../../formatters/currency'
import dateFormatter from '../../formatters/longDate'

const composeIndex = (parentIndex, index) => `${parentIndex}_col_${index}`

const TransactionItemsCard = ({
  contents, fields, fieldsPerRow, t, title,
}) => {
  const fieldSpan = fieldsPerRow === 3 ? 4 : 3

  const labels = {
    category: t('pages.transaction.transaction_details.category'),
    date: t('pages.transaction.transaction_details.date'),
    id: t('pages.transaction.transaction_details.id'),
    quantity: t('pages.transaction.transaction_details.quantity'),
    tangible: t('pages.transaction.transaction_details.tangible'),
    unit_price: t('pages.transaction.transaction_details.unit_price'),
    venue: t('pages.transaction.transaction_details.venue'),
  }

  return (
    <Card>
      <CardTitle title={t(title)} />
      <CardContent>
        <Grid>
          {
            contents.map((content, index) => (
              <Col
                palm={12}
                tablet={12}
                desk={12}
                tv={12}
                className={style.itemContainer}
                key={composeIndex('item', index)}
              >
                <h2 className={style.title}>{`${index + 1}. ${content.title}`}</h2>

                <Row className={style.content}>
                  {fields.id && (
                  <Col
                    palm={12}
                    tablet={6}
                    desk={fieldSpan}
                    tv={fieldSpan}
                    key={composeIndex('id', index)}
                    className={style.propertyCustom}
                  >
                    <Property
                      title={labels.id}
                      value={content.external_id || content.id}
                    />
                  </Col>
                  )}
                  {fields.unit_price && (
                  <Col
                    palm={12}
                    tablet={6}
                    desk={fieldSpan}
                    tv={fieldSpan}
                    key={composeIndex('price', index)}
                    className={style.propertyCustom}
                  >
                    <Property
                      title={labels.unit_price}
                      value={currencyFormatter(content.unit_price)}
                    />
                  </Col>
                  )}
                  {fields.quantity && (
                  <Col
                    palm={12}
                    tablet={6}
                    desk={fieldSpan}
                    tv={fieldSpan}
                    key={composeIndex('quantity', index)}
                    className={style.propertyCustom}
                  >
                    <Property
                      title={labels.quantity}
                      value={content.quantity}
                    />
                  </Col>
                  )}
                  {fields.tangible && (
                  <Col
                    palm={12}
                    tablet={6}
                    desk={fieldSpan}
                    tv={fieldSpan}
                    key={composeIndex('tangible', index)}
                    className={style.propertyCustom}
                  >
                    <Property
                      title={labels.tangible}
                      value={content.tangible ? 'Sim' : 'Não'}
                    />
                  </Col>
                  )}
                  {fields.category && (
                  <Col
                    palm={12}
                    tablet={6}
                    desk={fieldSpan}
                    tv={fieldSpan}
                    key={composeIndex('category', index)}
                    className={style.propertyCustom}
                  >
                    <Property
                      title={labels.category}
                      value={content.category}
                    />
                  </Col>
                  )}
                  {fields.date && (
                  <Col
                    palm={12}
                    tablet={6}
                    desk={fieldSpan}
                    tv={fieldSpan}
                    key={composeIndex('date', index)}
                    className={style.propertyCustom}
                  >
                    <Property
                      title={labels.date}
                      value={content.date && dateFormatter(content.date)}
                    />
                  </Col>
                  )}
                  {fields.venue && (
                  <Col
                    palm={12}
                    tablet={6}
                    desk={fieldSpan}
                    tv={fieldSpan}
                    key={composeIndex('venue', index)}
                    className={style.propertyCustom}
                  >
                    <Property
                      title={labels.venue}
                      value={content.venue}
                    />
                  </Col>
                  )}

                </Row>
              </Col>
            ))
          }
        </Grid>
      </CardContent>
    </Card>
  )
}

const shape = {
  category: PropTypes.string,
  date: PropTypes.string,
  external_id: PropTypes.string,
  id: PropTypes.string,
  object: PropTypes.string,
  quantity: PropTypes.number,
  tangible: PropTypes.bool,
  title: PropTypes.string,
  unit_price: PropTypes.number,
  venue: PropTypes.string,
}

const fieldsShape = {
  category: PropTypes.bool,
  date: PropTypes.bool,
  id: PropTypes.bool,
  object: PropTypes.bool,
  quantity: PropTypes.bool,
  tangible: PropTypes.bool,
  unit_price: PropTypes.bool,
  venue: PropTypes.bool,
}

TransactionItemsCard.propTypes = {
  contents: PropTypes.arrayOf(PropTypes.shape(shape)).isRequired,
  fields: PropTypes.shape(fieldsShape),
  fieldsPerRow: PropTypes.oneOf([3, 4]),
  t: PropTypes.func.isRequired,
  title: PropTypes.string,
}

TransactionItemsCard.defaultProps = {
  fields: {
    category: true,
    date: true,
    id: true,
    object: true,
    quantity: true,
    tangible: true,
    unit_price: true,
    venue: true,
  },
  fieldsPerRow: 3,
  title: 'pages.transaction.transaction_details.title',
}

export default TransactionItemsCard
