import {
  pathEq, allPass, anyPass, propEq,
} from 'ramda'

const hasAnticipation = pathEq(['capabilities', 'allow_transaction_anticipation'], true)

const hasAutomaticAnticipation = (defaultRecipient = {}) => allPass([
  propEq('automatic_anticipation_enabled', true),
  anyPass([propEq('automatic_anticipation_type', 'full'), propEq('automatic_anticipation_type', '1025')]),
])(defaultRecipient)

const hasAutomaticAnticipation1025 = (defaultRecipient = {}) => allPass([
  propEq('automatic_anticipation_enabled', true),
  propEq('automatic_anticipation_type', '1025'),
])(defaultRecipient)

const hasAutomaticAnticipationFull = (defaultRecipient = {}) => allPass([
  propEq('automatic_anticipation_enabled', true),
  propEq('automatic_anticipation_type', 'full'),
])(defaultRecipient)

const hasCompulsory = (company = {}, defaultRecipient = {}) => anyPass([
  (...args) => pathEq(['capabilities', 'allow_transaction_anticipation'], true)(args[0]),
  (...args) => propEq('automatic_anticipation_type', 'compulsory')(args[1]),
])(company, defaultRecipient)

export {
  hasAnticipation,
  hasAutomaticAnticipation,
  hasCompulsory,
  hasAutomaticAnticipation1025,
  hasAutomaticAnticipationFull,
}
