import React from 'react'
import PropTypes from 'prop-types'
import { Link as RouterLink } from 'react-router-dom'
import styles from './style.css'

const Link = ({
  link,
  onClick,
  text,
}) => (
  <RouterLink
    className={styles.pointer}
    to={link}
    onClick={onClick}
  >
    {text}
  </RouterLink>
)

Link.defaultProps = {
  onClick: null,
}

Link.propTypes = {
  link: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  text: PropTypes.node.isRequired,
}

export default Link
