import { format, sub, startOfWeek, endOfWeek, startOfMonth, endOfMonth, startOfYear, endOfYear } from "date-fns";
export const mountDateCloseOptions = (onChangeDate, setSelectedIndex, translate, optionsLabels) => {
  const setDatesOptions = (_ref, index) => {
    let {
      start,
      end
    } = _ref;
    if (start && end) {
      onChangeDate(new Date("".concat(start, " 00:00:00-0300")), new Date("".concat(end, " 00:00:00-0300")));
    }
    setSelectedIndex(index);
  };
  const options = [{
    event: setDatesOptions,
    label: translate(optionsLabels === null || optionsLabels === void 0 ? void 0 : optionsLabels.today),
    value: {
      start: format(new Date(), "yyyy-MM-dd"),
      end: format(new Date(), "yyyy-MM-dd")
    }
  }, {
    event: setDatesOptions,
    label: translate(optionsLabels === null || optionsLabels === void 0 ? void 0 : optionsLabels.yesterday),
    value: {
      start: format(sub(new Date(), {
        days: 1
      }), "yyyy-MM-dd"),
      end: format(sub(new Date(), {
        days: 1
      }), "yyyy-MM-dd")
    }
  }, {
    event: setDatesOptions,
    label: translate(optionsLabels === null || optionsLabels === void 0 ? void 0 : optionsLabels.week),
    value: {
      start: format(startOfWeek(new Date()), "yyyy-MM-dd"),
      end: format(endOfWeek(new Date()), "yyyy-MM-dd")
    }
  }, {
    event: setDatesOptions,
    label: translate(optionsLabels === null || optionsLabels === void 0 ? void 0 : optionsLabels.lastWeek),
    value: {
      start: format(startOfWeek(sub(new Date(), {
        weeks: 1
      })), "yyyy-MM-dd"),
      end: format(endOfWeek(sub(new Date(), {
        weeks: 1
      })), "yyyy-MM-dd")
    }
  }, {
    event: setDatesOptions,
    label: translate(optionsLabels === null || optionsLabels === void 0 ? void 0 : optionsLabels.month),
    value: {
      start: format(startOfMonth(new Date()), "yyyy-MM-dd"),
      end: format(endOfMonth(new Date()), "yyyy-MM-dd")
    }
  }, {
    event: setDatesOptions,
    label: translate(optionsLabels === null || optionsLabels === void 0 ? void 0 : optionsLabels.lastMonth),
    value: {
      start: format(startOfMonth(sub(new Date(), {
        months: 1
      })), "yyyy-MM-dd"),
      end: format(endOfMonth(sub(new Date(), {
        months: 1
      })), "yyyy-MM-dd")
    }
  }, {
    event: setDatesOptions,
    label: translate(optionsLabels === null || optionsLabels === void 0 ? void 0 : optionsLabels.year),
    value: {
      start: format(startOfYear(new Date()), "yyyy-MM-dd"),
      end: format(endOfYear(new Date()), "yyyy-MM-dd")
    }
  }, {
    event: setDatesOptions,
    label: translate(optionsLabels === null || optionsLabels === void 0 ? void 0 : optionsLabels.lastYear),
    value: {
      start: format(startOfYear(sub(new Date(), {
        years: 1
      })), "yyyy-MM-dd"),
      end: format(endOfYear(sub(new Date(), {
        years: 1
      })), "yyyy-MM-dd")
    }
  }, {
    event: setDatesOptions,
    label: translate(optionsLabels === null || optionsLabels === void 0 ? void 0 : optionsLabels.custom),
    value: {
      start: null,
      end: null
    }
  }];
  return options;
};