import React from 'react';
import { sidebarItemStyle } from './SidebarItem.style';
export const SidebarItem = props => React.createElement("div", {
  "data-testid": 'shared-components-sidebar_item',
  className: "\n      ".concat(sidebarItemStyle({
    status: props.index === props.selected ? 'selected' : 'common'
  }), "\n      ").concat(props.className, "\n    "),
  onClick: () => {
    props.handleSideBarOptionClick(props.label);
    props.event(props.value, props.index);
  }
}, React.createElement("span", {
  className: 'label'
}, props.label));