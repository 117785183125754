/* eslint-disable no-useless-escape */
/* eslint-disable sort-keys */
const banks = {
  '033': {
    regex_account: /^\D*\d{6}\D*$/,
    regex_agency: /^\D*\d{4}\D*$/,
    regex_digit_account: /^\D*\d{1}\D*$/,
    regex_digit_agency: null,
    hint_digit_agency: '',
    hint_digit_account: 'Informe 1 número',
    hint_account: 'Informe 6 números',
    hint_agency: 'Informe 4 números',
    account: 6,
    account_digit: 1,
    agency: 4,
    agency_digit: null,
  },
  '077': {
    regex_account: /^\D*\d{7,9}\D*$/,
    regex_agency: /^\D*\d{4}\D*$/,
    regex_digit_account: /^\D*\d{1}\D*$/,
    regex_digit_agency: /^\D*\d{1}\D*$/,
    hint_digit_agency: 'Informe 1 número',
    hint_digit_account: 'Informe 1 número',
    hint_account: 'Informe entre 7 e 9 números',
    hint_agency: 'Informe 4 números',
    account: [7, 9],
    account_digit: 1,
    agency: 4,
    agency_digit: 1,
  },
  237: {
    regex_account: /^\D*\d{7}\D*$/,
    regex_agency: /^\D*\d{4}\D*$/,
    regex_digit_account: /^\D*\d{1}\D*$/,
    regex_digit_agency: /^\D*\d{1}\D*$/,
    hint_digit_agency: 'Informe 1 número',
    hint_digit_account: 'Informe 1 número',
    hint_account: 'Informe 7 números',
    hint_agency: 'Informe 4 números',
    account: 7,
    account_digit: 1,
    agency: 4,
    agency_digit: 1,
  },
  341: {
    regex_account: /^\D*\d{5}\D*$/,
    regex_agency: /^\D*\d{5}\D*$/,
    regex_digit_account: /^\D*\d{1}\D*$/,
    regex_digit_agency: null,
    hint_digit_agency: '',
    hint_digit_account: 'Informe 1 número',
    hint_account: 'Informe 5 números',
    hint_agency: 'Informe 4 números',
    account: 5,
    account_digit: 1,
    agency: 4,
    agency_digit: null,
  },
  all: {
    regex_account: /^\D*\d{1,12}\D*$/,
    regex_agency: /^\D*\d{1,4}\D*$/,
    regex_digit_account: /^\d{1,2}$/,
    regex_digit_agency: /^\d{0,1}$/,
    hint_digit_agency: 'Informe até 1 número',
    hint_digit_account: 'Informe até 2 números',
    hint_account: 'Informe entre 1 e 12 números',
    hint_agency: 'Informe entre 1 e 4 números',
    account: [1, 12],
    account_digit: [1, 2],
    agency: 4,
    agency_digit: 1,
  },
}

export default banks
