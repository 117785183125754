import analiticaInstance from '../../plugins/analitica.config'

export default analiticaInstance.eventFactory('Pilot - Transactions', {
  calculateFees: {
    /**
    * @analitica Event to be tracked when user clicks to access rate calculator
    */
    clicked: () => ({
      label: 'pilot - transactions - calculate fees clicked',
      metadata: {
        event: 'Evento a ser rastreado quando o usuário clicar para acessar  a calculadora de taxas',
      },
    }),
  },

  createTransactions: {
    /**
    * @analitica Event to be fired every time the user clicks Create Transaction
    */
    clicked: () => ({
      label: 'pilot - transactions - create transaction clicked',
      metadata: {
        event: 'Evento a ser disparado toda vez que o usuário clicar em Criar transação',
      },
    }),
    /**
    * @analitica Event to be triggered every time the user clicks Next in the
    * customer data step in the transaction creation flow
    */
    clientData: () => ({
      label: 'pilot - transactions - client data advanced',
      metadata: {
        event: 'Evento a ser disparado toda vez que o usuário clicar em Avançar na etapa de dados do cliente, no fluxo de criação de uma transação',
      },
    }),
    /**
    * @analitica Event to be triggered every time the user finishes the flow of creating a
    * transaction, that is, when they click on finish in the flow of creating a transaction
    */
    finishedClicked: () => ({
      label: 'pilot - transactions - create transaction finished',
      metadata: {
        event: 'Evento a ser disparado toda vez que o usuário finalizar o fluxo de criar uma transação, ou seja quando ele clicar em finalizar no fluxo de criação de uma transação',
      },
    }),
    /**
    * @analitica Event to be triggered every time the user clicks Next in the transaction
    * items step, in the transaction creation flow
    */
    itensAdvance: () => ({
      label: 'pilot - transactions - transaction itens advanced',
      metadata: {
        event: 'Evento a ser disparado toda vez que o usuário clicar em Avançar na etapa de itens da transação, no fluxo de criação de uma transação',
      },
    }),
    /**
    * @analitica Event to be triggered every time the user clicks Next
    * in the payment step, in the transaction creation flow
    */
    paymentMethod: () => ({
      label: 'pilot - transactions - payment method advanced',
      metadata: {
        event: 'Evento a ser disparado toda vez que o usuário clicar em Avançar na etapa de pagamento, no fluxo de criação de uma transação',
      },
    }),
  },

  transactionsList: {
    /**
    * @analitica Event to be tracked when the user clicks to access the details of a transaction
    */
    detailsClicked: () => ({
      label: 'pilot - transactions - details clicked',
      metadata: {
        event: 'Evento a ser rastreado quando o usuário clicar para acessar  o detalhe de alguma transação',
      },
    }),
    /**
    * @analitica Event to be triggered when the client clicks on export table within transactions
    */
    exportTable: periodFilter => ({
      label: 'pilot - transactions - export clicked',
      metadata: {
        event: 'Evento a ser disparado quando o cliente clica em exportar tabela dentro de transações',
        'period filter': periodFilter,
      },
    }),
    /**
    * @analitica Event triggered every time transactions are viewed, that is, every time there is a new load
    */
    viewed: ({ filters, pageItens, periodFilter }) => ({
      label: 'pilot - transactions - viewed',
      metadata: {
        event: 'Evento disparado toda vez que transferências é visualizada, ou seja, toda vez que tiver um novo carregamento',
        'page itens': `${pageItens} itens por página`,
        'period filter': periodFilter,
        'transactions filters': filters,
      },
    }),
  },
})
