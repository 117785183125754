import { format } from "date-fns";
export const formatDateLabel = data => {
  return data ? format(new Date(data), "dd/MM/yyyy") : "";
};
export const formatDateToString = data => {
  return data ? format(new Date(data), "yyyy-MM-dd") : "";
};
export const formatStringToDate = data => {
  return data ? new Date("".concat(data, ":00:00.00-0300")) : null;
};
export const isDateRangeError = (date, _ref) => {
  let [min, max] = _ref;
  let validate = false;
  if (date) {
    if (min) {
      validate = date.getTime() < min.getTime();
    }
    if (max) {
      validate = date.getTime() > max.getTime();
    }
  }
  return validate ? "error" : "default";
};
export const onChangeFullDate = (entryDate, setDate) => {
  const rxDateFormat = /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/;
  if (entryDate && rxDateFormat.test(entryDate)) {
    setDate(formatStringToDate(entryDate));
  }
};