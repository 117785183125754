import React from 'react';
import { formBoxStyle } from './FormBox.style';
export const FormBox = _ref => {
  let {
    children,
    className
  } = _ref;
  return React.createElement("div", {
    className: "".concat(formBoxStyle(), " ").concat(className)
  }, children);
};