export function fromQueryString (qs) {
  return (qs || '')
    .replace(/\??(.*)/g, '$1')
    .split('&')
    .filter(Boolean)
    .map(str => str.split('='))
    .reduce(
      (res, [key, val]) => ({ ...res, [key]: decodeURIComponent(val) || null }),
      {}
    )
}

export function toQueryString (obj) {
  return Object.keys(obj)
    .filter(k => obj[k])
    .map(k => `${k}=${encodeURIComponent(obj[k])}`)
    .join('&')
}
