var __rest = this && this.__rest || function (s, e) {
  var t = {};
  for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
  }
  return t;
};
import React from "react";
import CloseIcon from 'emblematic-icons/svg/Close32.svg';
import { SearchInputTagStyle } from "./Tag.style";
export const SearchInputTag = _a => {
  var {
      value,
      onDelete
    } = _a,
    props = __rest(_a, ["value", "onDelete"]);
  return React.createElement("div", Object.assign({
    className: SearchInputTagStyle()
  }, props), value, !!onDelete && React.createElement("div", {
    onClick: onDelete,
    "data-testid": "search-input-close-tag"
  }, React.createElement(CloseIcon, null)));
};