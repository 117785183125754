import React from 'react'
import PropTypes from 'prop-types'
import percent from '../../../../formatters/percent'

const tPrefix = 'pages.payment_links.add_link.summary_step'

const SummaryCredtCardInstallments = ({
  creditCardConfig,
  t,
}) => {
  const {
    free_installments: freeInstallments,
    interest_rate: interestRate,
    max_installments: maxInstallments,
    min_installments: minInstallments = 1,
  } = creditCardConfig

  const onlyFirstIsFree = freeInstallments === 1

  const hasFreeInstallments = freeInstallments > 1

  const onlyLastHasTax = freeInstallments + 1 === maxInstallments

  const hasInstallmentsWithTax = freeInstallments < maxInstallments
    && !onlyLastHasTax

  const hasInterestRate = interestRate > 0

  return (
    <>
      {onlyFirstIsFree && (
        <span style={{ display: 'block' }}>
          {t(`${tPrefix}.credit_card_value_only_one`)}
        </span>
      )}
      {hasFreeInstallments && (
        <span style={{ display: 'block' }}>
          {t(`${tPrefix}.credit_card_value_more_than_one`,
            {
              first: minInstallments,
              last: freeInstallments,
            })}
        </span>
      )}
      {onlyLastHasTax && (
        <span style={{ display: 'block' }}>
            {t(`${tPrefix}.credit_card_value_last_with_tax`, { tax: maxInstallments }) }
            {
              hasInterestRate
              && t(`${tPrefix}.credit_card_value_more_than_one_with_tax_value`, {
                tax: percent(interestRate),
              })
            }
        </span>
      )}
      {hasInstallmentsWithTax && (
        <span style={{ display: 'block' }}>
          {t(`${tPrefix}.credit_card_value_more_than_one_with_tax`,
            {
              first: freeInstallments + 1,
              last: maxInstallments,
            })}
            {
              hasInterestRate
              && t(`${tPrefix}.credit_card_value_more_than_one_with_tax_value`, {
                tax: percent(interestRate),
              })
            }
        </span>
      )}
    </>
  )
}

SummaryCredtCardInstallments.propTypes = {
  creditCardConfig: PropTypes.shape({
    charge_transaction_fee: PropTypes.bool,
    free_installments: PropTypes.number,
    interest_rate: PropTypes.number,
    max_installments: PropTypes.number,
    min_installments: PropTypes.number,
  }).isRequired,
  t: PropTypes.func.isRequired,
}

export default SummaryCredtCardInstallments
