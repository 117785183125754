import { useState } from 'react'

function useImportMfe (MFEImport) {
  const [mount, setMount] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [hasError, setHasError] = useState(false)

  if (!mount && !hasError) {
    MFEImport.then((module) => {
      if (typeof module.default === 'function') {
        setMount(() => module.default)
      } else {
        setHasError(true)
      }
    })
      .catch(() => {
        setHasError(true)
      })
      .finally(() => setIsLoading(false))
  }

  return {
    hasError,
    isLoading,
    mount,
  }
}

export default useImportMfe
