import { css, theme } from "../../../theme";
export const statusTagStyle = css({
  padding: "4px 6px",
  display: "flex",
  borderRadius: 3,
  "& .label": {
    fontSize: 11,
    fontFamily: theme.font.bodyFontFamily,
    textTransform: "capitalize",
    color: theme.color.white
  },
  variants: {
    status: {
      success: {
        background: theme.color.picklerickGreen50
      },
      waiting: {
        background: theme.color.barneyPurple100
      },
      canceled: {
        background: theme.color.birdpersonGray800
      },
      error: {
        background: theme.color.bethPink50
      }
    }
  },
  defaultVariants: {
    status: "success"
  }
});