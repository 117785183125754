import moment from 'moment-timezone'

export default async (client) => {
  const date = moment()
  const hour = date.hour()

  const { calendar } = await client.business.requestBusinessCalendar(date.get('year'))

  const notBusinessHours = hour < 9 || hour >= 18

  if (notBusinessHours) {
    return false
  }

  const isWeekend = date.isoWeekday() === 6 || date.isoWeekday() === 7

  if (isWeekend) {
    return false
  }

  const dateWithoutHours = date.format('YYYY-MM-DD')

  const isHoliday = calendar.find(holiday => holiday
    .date === dateWithoutHours)

  if (isHoliday) {
    return false
  }

  return true
}
