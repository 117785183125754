import React, { useState, useRef } from 'react'
import {
  PopoverCheckboxGroup,
  Button,
  isMomentPropValidation,
} from 'former-kit'
import PropTypes from 'prop-types'

const PopoverCheckbox = ({
  checkboxKey,
  handleFiltersChange,
  items,
  name,
  query,
  selectedFilters,
}) => {
  const [collapsed, setCollapsed] = useState(false)
  const ref = useRef(null)

  return (
    <div ref={ref}>
      <PopoverCheckboxGroup
        fieldKey={checkboxKey}
        label={name}
        onChange={(data) => {
          handleFiltersChange({
            ...query,
            filters: {
              ...query.filters,
              [checkboxKey]: data,
            },
          })
        }}
        options={items}
        popover={{
          onClick: () => {},
          onClickOutside: () => setCollapsed(false),
          placement: 'bottomStart',
          visible: collapsed,
        }}
        value={selectedFilters[checkboxKey] || []}
        key={checkboxKey}
      >
        <Button
          relevance="low"
          fill="outline"
          iconAlignment="end"
          onClick={() => setCollapsed(!collapsed)}
        >
          {name}
        </Button>
      </PopoverCheckboxGroup>
    </div>
  )
}

PopoverCheckbox.propTypes = {
  checkboxKey: PropTypes.string.isRequired,
  handleFiltersChange: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
  name: PropTypes.string.isRequired,
  query: PropTypes.shape({
    dates: PropTypes.shape({
      end: isMomentPropValidation,
      start: isMomentPropValidation,
    }),
    filters: PropTypes.shape({}),
    lastUpdate: PropTypes.shape({
      end: isMomentPropValidation,
      start: isMomentPropValidation,
    }),
    search: PropTypes.string,
  }).isRequired,
  selectedFilters: PropTypes.shape({}).isRequired,
}

export default PopoverCheckbox
