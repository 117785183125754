import React from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Card,
  CardActions,
  CardContent,
} from 'former-kit'

import Customer from './Customer'
import Payment from './Payment'
import Items from './Items'
import Subscription from './Subscription'

import style from './style.css'
import transactionsAnalitica from '../../helpers/analiticaEvents/transactionsAnalitica'

const tPrefix = 'components.review_transaction_or_subscription'

const ReviewTransactionOrSubscription = ({
  customer,
  isLoading,
  items,
  onBack,
  onSubmit,
  payment,
  plan_id: planId,
  t,
}) => {
  function handleSubmit () {
    transactionsAnalitica.events.createTransactions.finishedClicked()
    onSubmit({
      customer,
      items,
      payment,
      plan_id: planId,
    })
  }

  return (
    <Card>
      <CardContent>
        {!planId && (
        <h2>{t(`${tPrefix}.title_transaction`)}</h2>
        )}
        {planId && (
        <h2>{t(`${tPrefix}.title_subscription`)}</h2>
        )}
        <div className={style.review}>
          <Customer {...customer} t={t} tPrefix={tPrefix} />
          <Payment {...payment} t={t} tPrefix={tPrefix} />
          {!planId && (
          <Items
            amount={payment.amount}
            items={items}
            t={t}
            tPrefix={tPrefix}
          />
          )}
          {planId && (
          <Subscription
            amount={payment.amount}
            plan_id={planId}
            t={t}
            tPrefix={tPrefix}
          />
          )}
        </div>
      </CardContent>
      <CardActions>
        <Button
          disabled={isLoading}
          onClick={onBack}
          fill="outline"
        >
          {t(`${tPrefix}.back`)}
        </Button>
        <Button
          data-testid="button-advance"
          disabled={isLoading}
          loading={isLoading}
          onClick={handleSubmit}
        >
          {t(`${tPrefix}.submit`)}
        </Button>
      </CardActions>
    </Card>
  )
}

ReviewTransactionOrSubscription.propTypes = {
  customer: PropTypes.shape({
    document: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  isLoading: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.shape({
    quantity: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    unit_price: PropTypes.number.isRequired,
  })),
  onBack: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  payment: PropTypes.shape({
    amount: PropTypes.number.isRequired,
    boleto_expiration_date: PropTypes.string,
    card_number: PropTypes.string,
    installments: PropTypes.number,
    method: PropTypes.string.isRequired,
    pix_expiration_date: PropTypes.string,
  }).isRequired,
  plan_id: PropTypes.number,
  t: PropTypes.func.isRequired,
}

ReviewTransactionOrSubscription.defaultProps = {
  isLoading: false,
  items: null,
  plan_id: null,
}

export default ReviewTransactionOrSubscription
