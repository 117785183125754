import {
  find, is, juxt, pipe,
} from 'ramda'
import createLessThanValidation from './lessThan'

const onlyNumbers = /^\d+$/

const numberValidation = message => value => !onlyNumbers.test(value) && message

export const isFloat = message => value => !(/\d|,/g).test(value) && message

const applyValidations = (validations, value) => pipe(
  juxt(validations),
  find(is(String))
)(value)

const isNumber = errorMessage => numberValidation(errorMessage)
const isLessThan = (minValue, errorMessage) => createLessThanValidation(
  minValue, errorMessage
)

export const requiredNumberGreaterThan = (
  minValue, errorMessage
) => value => applyValidations([
  isNumber(errorMessage),
  isLessThan(minValue, errorMessage),
], value)

export const validateNumberGreaterThan = (
  minValue, errorMessage
) => (value) => {
  if (!value) {
    return false
  }
  return applyValidations([
    isNumber(errorMessage),
    isLessThan(minValue, errorMessage),
  ], value)
}

export default numberValidation
