import { css, theme } from "../../../theme";
const clearCalendarIconStyle = {
  display: "none",
  "-moz-appearance": "none",
  "-webkit-appearance": "none",
  appearance: "none",
  filter: "invert(1)",
  visibility: "hidden !important"
};
export const inputDateStyle = css({
  display: "flex",
  border: "1px solid",
  padding: theme.space.tiny,
  borderRadius: theme.radii.default,
  borderColor: theme.color.squanchyGray50,
  height: 44,
  "&::-webkit-inner-spin-button": clearCalendarIconStyle,
  "&::-webkit-calendar-picker-indicator": clearCalendarIconStyle,
  "&::-webkit-clear-button": clearCalendarIconStyle,
  "&::-moz-inner-spin-button": clearCalendarIconStyle,
  "&::-moz-calendar-picker-indicator": clearCalendarIconStyle,
  "&::-moz-clear-button": clearCalendarIconStyle,
  "&::-webkit-input-placeholder": clearCalendarIconStyle,
  "&:focus": {
    borderColor: theme.color.squanchyGray100,
    borderWidth: 1,
    outline: "none",
    boxShadow: "none"
  },
  variants: {
    status: {
      default: {
        color: theme.color.birdpersonGray700
      },
      success: {
        borderColor: theme.color.picklerickGreen50,
        color: theme.color.picklerickGreen50
      },
      error: {
        borderColor: theme.color.bethPink50,
        color: theme.color.bethPink50
      }
    }
  },
  defaultVariants: {
    status: "default"
  }
});