var __rest = this && this.__rest || function (s, e) {
  var t = {};
  for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
  }
  return t;
};
import React from "react";
import SearchIcon from 'emblematic-icons/svg/Search32.svg';
import { SearchInputWrapperStyle } from "./InputWrapper.style";
import { SearchInputTag } from "../Tag/Tag";
export const SearchInputWrapper = _a => {
  var {
      status,
      selecteds,
      hideInput,
      onDelete
    } = _a,
    props = __rest(_a, ["status", "selecteds", "hideInput", "onDelete"]);
  return React.createElement("div", {
    className: "".concat(SearchInputWrapperStyle({
      status
    }))
  }, React.createElement(SearchIcon, null), React.createElement("div", {
    className: "tags-wrapper"
  }, selecteds.map(selected => React.createElement(SearchInputTag, {
    key: selected.key,
    value: "".concat(selected.tag, " ").concat(selected.value),
    onDelete: () => onDelete(selected),
    "data-testid": "search-input-selected-tag-".concat(selected.key)
  }))), !hideInput && React.createElement("input", Object.assign({
    type: "text"
  }, props)));
};