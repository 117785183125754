import React, { Fragment, useCallback } from "react";
import BackArrow from "emblematic-icons/svg/ChevronBack24.svg";
import ForwardArrow from "emblematic-icons/svg/ChevronForward24.svg";
import { Pagination, Button, Label, CurrentPage, TotalPages, Separator } from "./CursorPagination.style";
import { AddTheme } from "../../../theme/addTheme";
export const CursorPagination = AddTheme(_ref => {
  let {
    currentPage,
    disabled,
    format,
    onNext,
    onPrevious,
    rangeSeparatorLabel,
    totalNumberOfPages
  } = _ref;
  const previous = useCallback(() => {
    if (currentPage > 0) onPrevious();
  }, [currentPage, onPrevious]);
  const next = useCallback(() => {
    if (currentPage < totalNumberOfPages) onNext();
  }, [currentPage, onNext, totalNumberOfPages]);
  const ITS_THE_FIRST_PAGE = currentPage === 1;
  const ITS_THE_LAST_PAGE = currentPage === totalNumberOfPages;
  return React.createElement(Pagination, null, React.createElement(Button, {
    type: "prev",
    disabled: disabled || ITS_THE_FIRST_PAGE,
    "data-testid": "cursor-pagination-prev-button",
    onClick: previous
  }, React.createElement(BackArrow, {
    width: "12px",
    height: "12px"
  })), React.createElement(Label, null, React.createElement(CurrentPage, null, currentPage), format === "range" && React.createElement(Fragment, null, React.createElement(Separator, null, rangeSeparatorLabel), React.createElement(TotalPages, null, totalNumberOfPages))), React.createElement(Button, {
    type: "next",
    disabled: disabled || ITS_THE_LAST_PAGE,
    "data-testid": "cursor-pagination-next-button",
    onClick: next
  }, React.createElement(ForwardArrow, {
    width: "12px",
    height: "12px"
  })));
});