import React from 'react';
import { Col as GridCol } from "former-kit";
import { colStyle } from './Col.style';
export const Col = props => {
  return React.createElement(GridCol, {
    desk: props.cols,
    tv: props.cols,
    palm: 12,
    tablet: 6
  }, React.createElement("div", {
    className: colStyle()
  }, props.children));
};