import moment from 'moment'
import {
  assocPath,
  is,
  isNil,
  isEmpty,
  either,
  map,
  omit,
  when,
  pipe,
  reject,
} from 'ramda'

const clean = o => pipe(
  reject(either(isNil, isEmpty)),
  map(when(is(Object), clean))
)(o)

const formatZipcode = zipcode => zipcode.replace('-', '')
const formatCountry = country => country?.toLowerCase()
const formatDateISO = date => (date ? moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD') : '')

const mapperPayment = (plans, payment) => {
  let result = pipe(
    assocPath(['billing', 'address', 'country'], formatCountry(payment.billing.address.country)),
    assocPath(['billing', 'address', 'zipcode'], formatZipcode(payment.billing.address.zipcode)),
    clean
  )(payment)

  if (payment?.shipping) {
    result = pipe(
      assocPath(['shipping', 'address', 'country'], formatCountry(payment.shipping.address.country)),
      assocPath(['shipping', 'address', 'zipcode'], formatZipcode(payment.shipping.address.zipcode)),
      assocPath(['shipping', 'delivery_date'], formatDateISO(payment.shipping.delivery_date)),
      assocPath(['shipping', 'expedited'], JSON.parse(payment.shipping.expedited)),
      assocPath(['shipping', 'fee'], +payment.shipping.fee),
      clean
    )(result)
  }

  if (payment.payment_method === 'credit_card') {
    result = {
      ...result,
      card_expiration_date: payment.card_expiration_date?.replace('/', ''),
      card_number: payment.card_number?.replace(/\s/g, ''),
      installments: +payment.installments,
    }
  }

  if (payment.payment_method === 'boleto') {
    result = {
      ...result,
      boleto_expiration_date: formatDateISO(payment.boleto_expiration_date),
    }
  }

  if (payment.payment_method === 'pix') {
    result = {
      ...result,
      pix_expiration_date: formatDateISO(payment.pix_expiration_date),
    }
  }

  if (plans?.length) {
    const planId = +result.plan_id
    result = {
      ...omit(['installments', 'boleto_expiration_date'], result),
      amount: plans.find(({ value }) => +value === planId)?.amount,
      plan_id: planId,
    }
  }

  return result
}

export default mapperPayment
