import { useLocation, useHistory } from 'react-router-dom'
import { fromQueryString, toQueryString } from '../utils/queryString'

function useQueryParams () {
  const { search } = useLocation()
  const history = useHistory()

  const updateQueryParams = (newParams) => {
    history.push({ search: toQueryString(newParams) })
  }

  return {
    params: fromQueryString(search),
    update: updateQueryParams,
  }
}

export default useQueryParams
