import React from 'react';
import { isStringOrNumber } from '../../../helpers/isStringOrNumber';
import { isHtml } from '../../../helpers/isHtml';
import { propertyStyle } from './Property.style';
export const Property = _ref => {
  let {
    className,
    title,
    value
  } = _ref;
  return React.createElement("div", {
    "data-testid": 'cf-component-property',
    className: "".concat(propertyStyle(), " ").concat(className !== null && className !== void 0 ? className : '')
  }, React.createElement("h4", {
    className: "title"
  }, title), isStringOrNumber(value) ? React.createElement("span", {
    className: "value"
  }, isHtml(value) ? React.createElement("div", {
    dangerouslySetInnerHTML: {
      __html: value
    }
  }) : value) : value);
};