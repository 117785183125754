import countries from '../../models/countries'

const getCountries = t => (countries.map(value => (
  {
    name: t(`models.countries.${value}`),
    value,
  }
)))

export default getCountries
