import { useState, useEffect } from 'react'
import { cepApiUrl } from '../../environment'

const getOnlyNumbers = string => string.replace(/\D/g, '')

export default function useCep () {
  const [numberCep, setNumberCep] = useState(null)
  const [cep, setCep] = useState({ loading: false, response: null })

  useEffect(() => {
    const fetchCep = async () => {
      try {
        const parsedNumber = getOnlyNumbers(numberCep)
        if (parsedNumber && parsedNumber.length === 8) {
          setCep({ loading: true, response: null })
          const body = await fetch(`${cepApiUrl}/${numberCep}`)
          const response = await body.json()
          setCep({ loading: false, response })
        }
      } catch (error) {
        setCep({ loading: false, response: null })
      }
    }

    fetchCep()
  }, [numberCep])

  return [cep, setNumberCep]
}
