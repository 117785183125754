import { css, theme } from '../../../theme';
export const dateSelectorStyle = css({
  display: 'flex',
  padding: 0,
  zIndex: 1000,
  '& .date-selector-content': {
    display: 'flex'
  },
  '& .date-selector-sidebar': {
    borderRight: "1px solid ".concat(theme.color.squanchyGray50)
  },
  '& .date-selector-dates': {
    display: 'block',
    flexDirection: 'column',
    width: 'auto',
    minWidth: '690px'
  },
  '& .date-selector-calendar': {
    borderBottom: "1px solid ".concat(theme.color.squanchyGray50)
  },
  '& .date-selector-forms': {
    display: 'flex',
    alignItems: 'center'
  },
  '& .date-selector-input': {
    maxWidth: 200
  },
  '& .date-selector-dash': {
    display: 'block',
    width: 8,
    height: 1,
    background: theme.color.squanchyGray50
  }
});
export const dateSelectorInputStyle = css({
  marginBottom: 0
});
export const dateSelectorSidebarStyle = css({
  paddingLeft: '0 !important'
});