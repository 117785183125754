import React from 'react';
import { Card, CardContent, Grid, Row as GridRow } from "former-kit";
import { Row } from './Row/Row';
import { Col } from './Col/Col';
import { useCalculateNumberOfColumns } from './hooks/useCalculateNumberOfColumns';
const DetailsCard = _ref => {
  let {
    children,
    fixedCols
  } = _ref;
  const {
    cols
  } = useCalculateNumberOfColumns(children, fixedCols);
  return React.createElement(Card, null, React.createElement(CardContent, null, React.createElement(Grid, null, React.createElement(GridRow, null, Array.isArray(children) ? children.map(c => React.cloneElement(c, {
    cols
  })) : React.cloneElement(children, {
    cols
  })))));
};
DetailsCard.Row = Row;
DetailsCard.Col = Col;
export { DetailsCard };