var __rest = this && this.__rest || function (s, e) {
  var t = {};
  for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
  }
  return t;
};
import React, { useState } from "react";
import { SearchInputWrapper } from "./InputWrapper/InputWrapper";
import { SearchInputPopover } from "./Popover/Popover";
import { buildOptionsSelected, buildPlaceholder, filterOptionsThatPatternMatch, getOptionsWhitoutSelecteds, getStatus } from "./SearchInput.helpers";
import { SearchInputBoxStyle, SearchInputStyle } from "./SearchInput.style";
export const SearchInput = _a => {
  var {
      options,
      selecteds,
      tPlaceholderKey,
      tFunc,
      onSelect,
      onDelete
    } = _a,
    props = __rest(_a, ["options", "selecteds", "tPlaceholderKey", "tFunc", "onSelect", "onDelete"]);
  const [searchValue, setSearchValue] = useState('');
  const status = getStatus(searchValue.length > 0);
  const optionsThatPatternMatch = filterOptionsThatPatternMatch(searchValue, options);
  const optionsSelected = buildOptionsSelected(selecteds, options);
  const filteredOptions = getOptionsWhitoutSelecteds(selecteds, optionsThatPatternMatch);
  const hasSelectedAll = optionsSelected.length === options.length;
  const handleSelectOption = option => {
    onSelect({
      key: option.key,
      value: searchValue
    });
    setSearchValue('');
  };
  const placeHolder = buildPlaceholder(options, selecteds, tFunc, tPlaceholderKey);
  return React.createElement("div", {
    className: SearchInputStyle()
  }, React.createElement("div", {
    className: SearchInputBoxStyle({
      status
    })
  }, React.createElement(SearchInputWrapper, Object.assign({}, props, {
    status: status,
    selecteds: optionsSelected,
    hideInput: hasSelectedAll,
    onChange: e => {
      setSearchValue(e.target.value);
    },
    onDelete: e => onDelete({
      key: e.key,
      value: e.value
    }),
    value: searchValue,
    placeholder: placeHolder
  }))), React.createElement(SearchInputPopover, {
    status: status,
    options: filteredOptions,
    tFunc: tFunc,
    onSelect: handleSelectOption
  }));
};