import React, { useState, useEffect } from 'react'
import { omit } from 'ramda'
import moment from 'moment'
import PropTypes from 'prop-types'
import Form from 'react-vanilla-form'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  FormCheckbox,
  FormDropdown,
  FormInput,
} from 'former-kit'
import CurrencyInput from '../CurrencyInput'
import useCep from '../../hooks/useCep'

import isNilOrEmpty from '../../validation/isNilOrEmpty'
import getCountries from '../../utils/helpers/getCountries'
import formatCurrency from '../../formatters/currency'
import validations from './validations'
import mapperPayment from './mapper'

import styles from './style.css'
import transactionsAnalitica from '../../helpers/analiticaEvents/transactionsAnalitica'

const tPrefix = 'components.payment_form'

const optionsPaymentMethods = (t, hasPix, plan) => {
  const options = [
    {
      name: t(`${tPrefix}.payment.credit_card`),
      value: 'credit_card',
    },
    {
      name: t(`${tPrefix}.payment.boleto`),
      value: 'boleto',
    },
  ]

  if (hasPix) {
    options.push({
      name: t(`${tPrefix}.payment.pix`),
      value: 'pix',
    })
  }

  if (plan) {
    return options.filter(
      ({ value }) => plan.paymentMethods.includes(value)
    )
  }

  return options
}

const optionsInstallments = (amount, t) => (
  Array.from(Array(12)).map((_, i) => {
    const installment = i + 1
    return {
      name: t(`${tPrefix}.payment.installment`, {
        amount: formatCurrency(amount / installment),
        installment,
      }),
      value: installment.toString(),
    }
  })
)

const optionsExpedited = t => [
  {
    name: t(`${tPrefix}.shipping.expedited_true`),
    value: 'true',
  },
  {
    name: t(`${tPrefix}.shipping.expedited_false`),
    value: 'false',
  },
]

const buildFormToPayment = form => (
  Object.entries(form).reduce((object, [prop, value]) => {
    const keys = prop.split('.')
    const lastKey = keys.pop()

    /* eslint-disable no-param-reassign */
    /* eslint-disable no-return-assign */
    keys.reduce((o, k) => o[k] = o[k] || {}, object)[lastKey] = value
    return object
  }, {})
)

const formatDate = date => date && moment(date).format('DD/MM/YYYY')

const findPlan = (plans, planId) => plans?.find(plan => plan.value === planId)

const PaymentForm = ({
  amount,
  billing,
  hasPix,
  onBack,
  onSubmit,
  payment,
  plans,
  shipping,
  t,
  type,
}) => {
  const [billingCep, setBillingCep] = useCep()
  const [shippingCep, setShippingCep] = useCep()
  const [paymentMethod, setPaymentMethod] = useState(payment.payment_method || 'credit_card')
  const [hasShipping, setHasShipping] = useState(!!shipping.name)
  const [sameBilling, setSameBilling] = useState(
    (shipping.name === billing.name
      && shipping.address.zipcode === billing.address.zipcode)
    ?? true
  )
  const [isInvalidForm, setInvalidForm] = useState(true)
  const [errors, setErrors] = useState({})
  const [formValue, setFormValue] = useState(() => {
    let result = {
      'billing.address.city': billing.address.city,
      'billing.address.complementary': billing.address.complementary,
      'billing.address.neighborhood': billing.address.neighborhood,
      'billing.address.state': billing.address.state,
      'billing.address.street': billing.address.street,
      'billing.address.street_number': billing.address.street_number,
      'billing.address.zipcode': billing.address.zipcode,
    }

    if (type === 'transaction') {
      result = {
        ...result,
        'billing.address.country': billing.address.country.toUpperCase(),
        'billing.name': billing.name,
      }
    }

    if (type === 'subscription') {
      result = {
        ...result,
        plan_id: payment.plan_id?.toString(),
      }
    }

    if (paymentMethod === 'credit_card') {
      result = {
        ...result,
        card_cvv: payment.card_cvv,
        card_expiration_date: payment.card_expiration_date,
        card_holder_name: payment.card_holder_name,
        card_number: payment.card_number,
        installments: payment.installments?.toString() || '1',
        payment_method: paymentMethod,
      }
    }

    if (paymentMethod === 'boleto') {
      result = {
        ...result,
        boleto_expiration_date: formatDate(payment.boleto_expiration_date),
        payment_method: paymentMethod,
      }
    }

    if (paymentMethod === 'pix') {
      result = {
        ...result,
        payment_method: paymentMethod,
        pix_expiration_date: formatDate(payment.pix_expiration_date),
      }
    }

    return result
  })

  useEffect(() => {
    let result = formValue

    if (hasShipping) {
      result = {
        ...result,
        'shipping.address.city': shipping.address.city,
        'shipping.address.complementary': shipping.address?.complementary,
        'shipping.address.country': shipping.address.country.toUpperCase(),
        'shipping.address.neighborhood': shipping.address.neighborhood,
        'shipping.address.state': shipping.address.state,
        'shipping.address.street': shipping.address.street,
        'shipping.address.street_number': shipping.address.street_number,
        'shipping.address.zipcode': shipping.address.zipcode,
        'shipping.delivery_date': shipping.delivery_date ? formatDate(shipping.delivery_date) : '',
        'shipping.expedited': shipping.expedited.toString(),
        'shipping.fee': shipping.fee.toString(),
        'shipping.name': shipping.name,
      }
    }

    if (hasShipping && sameBilling) {
      result = {
        ...result,
        'shipping.address.city': result['billing.address.city'],
        'shipping.address.complementary': result['billing.address.complementary'],
        'shipping.address.country': result['billing.address.country']?.toUpperCase(),
        'shipping.address.neighborhood': result['billing.address.neighborhood'],
        'shipping.address.state': result['billing.address.state'],
        'shipping.address.street': result['billing.address.street'],
        'shipping.address.street_number': result['billing.address.street_number'],
        'shipping.address.zipcode': result['billing.address.zipcode'],
        'shipping.name': result['billing.name'],
      }
    }

    if (!hasShipping) {
      Object.entries(formValue).forEach(([prop]) => {
        if (prop.includes('shipping')) {
          delete result[prop]
        }
      })
    }

    setFormValue({ ...result, payment_method: paymentMethod })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasShipping, sameBilling])

  useEffect(() => {
    if (billingCep.response) {
      setFormValue({
        ...formValue,
        'billing.address.city': billingCep.response.city,
        'billing.address.neighborhood': billingCep.response.neighborhood,
        'billing.address.state': billingCep.response.state,
        'billing.address.street': billingCep.response.street,
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billingCep])

  useEffect(() => {
    if (shippingCep.response) {
      setFormValue({
        ...formValue,
        'shipping.address.city': shippingCep.response.city,
        'shipping.address.neighborhood': shippingCep.response.neighborhood,
        'shipping.address.state': shippingCep.response.state,
        'shipping.address.street': shippingCep.response.street,
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shippingCep])

  useEffect(() => {
    const notNeedValidate = [
      'billing.address.complementary',
      'shipping.address.complementary',
      'shipping.expedited',
      'shipping.delivery_date',
      type === 'subscription' ? 'boleto_expiration_date' : '',
    ]
    const hasSomeEmptyFormValue = Object
      .entries(formValue)
      .filter(([prop]) => !notNeedValidate.includes(prop))
      .flat()
      .includes('')
    setInvalidForm(hasSomeEmptyFormValue)
  }, [formValue, errors, type])

  const onSubmitForm = (form, submitError) => {
    transactionsAnalitica.events.createTransactions.paymentMethod()
    const result = mapperPayment(plans, buildFormToPayment(form))

    if (isNilOrEmpty(submitError)) {
      onSubmit(result)
    }
  }

  const onChangeForm = (form, formErrors) => {
    setErrors(formErrors)
    setFormValue(form)
  }

  const onChangePaymentMethod = (method) => {
    setPaymentMethod(method)
    const creditCardParams = [
      'card_cvv',
      'card_expiration_date',
      'card_holder_name',
      'card_number',
      'installments',
    ]

    if (method === 'credit_card') {
      setFormValue(omit(['boleto_expiration_date', 'pix_expiration_date'], {
        ...formValue,
        card_cvv: '',
        card_expiration_date: '',
        card_holder_name: '',
        card_number: '',
        installments: '1',
        payment_method: method,
      }))
    }

    if (method === 'boleto') {
      setFormValue(omit([...creditCardParams, 'pix_expiration_date'], {
        ...formValue,
        boleto_expiration_date: '',
        payment_method: method,
      }))
    }

    if (method === 'pix') {
      setFormValue(omit([...creditCardParams, 'boleto_expiration_date'], {
        ...formValue,
        payment_method: method,
        pix_expiration_date: '',
      }))
    }
  }

  const onChangePlanId = (planId) => {
    setFormValue({
      ...formValue,
      payment_method: '',
      plan_id: planId,
    })
  }

  const onChangeCep = (e) => {
    const cepNumber = e.target.value.replace('-', '')
    return (cepNumber.length === 8) && cepNumber
  }

  return (
    <Card>
      <Form
        data={formValue}
        onChange={onChangeForm}
        validateOn="blur"
        validation={validations({ hasShipping, t, tPrefix })}
        onSubmit={onSubmitForm}
      >
        <CardContent>
          <h2 className={styles.title}>{t(`${tPrefix}.payment.title`)}</h2>
          <div className={styles.formGrid}>
            {type === 'subscription' && (
            <FormDropdown
              options={plans}
              name="plan_id"
              data-testid="dropdown-plan-id"
              label={t(`${tPrefix}.plan_id`)}
              onChange={e => onChangePlanId(e.target.value)}
            />
            )}
            <FormDropdown
              options={optionsPaymentMethods(
                t,
                hasPix,
                findPlan(plans, formValue?.plan_id)
              )}
              name="payment_method"
              label={t(`${tPrefix}.payment.payment_method`)}
              onChange={e => onChangePaymentMethod(e.target.value)}
              data-testid="payment-method"
            />
            {type === 'transaction' && paymentMethod === 'boleto' && (
            <div>
              <FormInput
                type="text"
                mask="11/11/1111"
                maskChar={null}
                name="boleto_expiration_date"
                data-testid="boleto_expiration_date"
                label={t(`${tPrefix}.payment.boleto_expiration_date`)}
              />
            </div>
            )}
            {paymentMethod === 'pix' && (
            <div>
              <FormInput
                type="text"
                mask="11/11/1111"
                maskChar={null}
                name="pix_expiration_date"
                data-testid="pix_expiration_date"
                label={t(`${tPrefix}.payment.pix_expiration_date`)}
              />
            </div>
            )}
            {paymentMethod === 'credit_card' && (
            <>
              <FormInput
                type="text"
                className="fs-exclude"
                mask="1111 1111 1111 1111 111"
                maskChar={null}
                name="card_number"
                data-testid="card_number"
                label={t(`${tPrefix}.payment.card_number`)}
              />
              <FormInput
                type="text"
                name="card_holder_name"
                data-testid="card_holder_name"
                label={t(`${tPrefix}.payment.card_holder_name`)}
              />
              <FormInput
                type="text"
                mask="11/11"
                maskChar={null}
                name="card_expiration_date"
                data-testid="card_expiration_date"
                label={t(`${tPrefix}.payment.card_expiration_date`)}
              />
              <FormInput
                className="fs-exclude"
                type="text"
                mask="1111"
                maskChar={null}
                name="card_cvv"
                data-testid="card_cvv"
                label={t(`${tPrefix}.payment.card_cvv`)}
              />
              {type === 'transaction' && (
                <FormDropdown
                  options={optionsInstallments(amount, t)}
                  name="installments"
                  data-testid="installments"
                  label={t(`${tPrefix}.payment.installments`)}
                />
              )}
            </>
            )}
          </div>
          <h2 className={styles.subtitle}>{t(`${tPrefix}.billing.title`)}</h2>
          <div className={styles.formGrid}>
            {type === 'transaction' && (
              <FormInput
                type="text"
                name="billing.name"
                data-testid="billing.name"
                label={t(`${tPrefix}.billing.name`)}
              />
            )}
            {type === 'transaction' && (
              <FormDropdown
                options={getCountries(t)}
                name="billing.address.country"
                data-testid="billing.address.country"
                label={t(`${tPrefix}.address.country`)}
              />
            )}
            <FormInput
              type="text"
              mask="11111-111"
              maskChar={null}
              name="billing.address.zipcode"
              data-testid="billing.address.zipcode"
              label={t(`${tPrefix}.address.zipcode`)}
              onChange={e => setBillingCep(onChangeCep(e))}
              disabled={billingCep?.loading}
            />
            <FormInput
              type="text"
              name="billing.address.city"
              data-testid="billing.address.city"
              label={t(`${tPrefix}.address.city`)}
              disabled={billingCep?.loading}
            />
            <FormInput
              className={styles.uppercase}
              type="text"
              name="billing.address.state"
              maxLength="2"
              label={t(`${tPrefix}.address.state`)}
              data-testid="billing.address.state"
              disabled={billingCep?.loading}
            />
            <FormInput
              type="text"
              name="billing.address.neighborhood"
              label={t(`${tPrefix}.address.neighborhood`)}
              data-testid="billing.address.neighborhood"
              disabled={billingCep?.loading}
            />
            <FormInput
              type="text"
              name="billing.address.street"
              label={t(`${tPrefix}.address.street`)}
              data-testid="billing.address.street"
              disabled={billingCep?.loading}
            />
            <FormInput
              type="text"
              name="billing.address.street_number"
              label={t(`${tPrefix}.address.number`)}
              data-testid="billing.address.street_number"
              disabled={billingCep?.loading}
            />
            <FormInput
              type="text"
              name="billing.address.complementary"
              data-testid="billing.address.complementary"
              label={t(`${tPrefix}.address.complementary`)}
            />
          </div>
          {type === 'transaction' && (
            <div className={styles.checkboxShipping}>
              <FormCheckbox
                value="has_shipping"
                label={t(`${tPrefix}.shipping.has_shipping`)}
                data-testid="has_shipping"
                checked={hasShipping}
                onChange={() => setHasShipping(!hasShipping)}
              />
            </div>
          )}
          {hasShipping && (
            <>
              <h2 className={styles.subtitle}>{t(`${tPrefix}.shipping.title`)}</h2>
              <div className={styles.checkboxShipping}>
                <FormCheckbox
                  value="same_billing"
                  label={t(`${tPrefix}.shipping.same_billing`)}
                  data-testid="same_billing"
                  checked={sameBilling}
                  onChange={() => setSameBilling(!sameBilling)}
                />
              </div>
              <div className={styles.formGrid}>
                <FormInput
                  type="text"
                  name="shipping.name"
                  data-testid="shipping.name"
                  label={t(`${tPrefix}.shipping.name`)}
                />
                <FormDropdown
                  options={optionsExpedited(t)}
                  name="shipping.expedited"
                  data-testid="shipping.expedited"
                  label={t(`${tPrefix}.shipping.expedited`)}
                />
                <FormInput
                  type="text"
                  name="shipping.fee"
                  data-testid="shipping.fee"
                  label={t(`${tPrefix}.shipping.fee`)}
                  renderer={props => (
                    <CurrencyInput
                      {...props}
                    />
                  )}
                />
                <FormInput
                  type="text"
                  mask="11/11/1111"
                  maskChar={null}
                  name="shipping.delivery_date"
                  data-testid="shipping.delivery_date"
                  label={t(`${tPrefix}.shipping.delivery_date`)}
                />
                <FormDropdown
                  options={getCountries(t)}
                  name="shipping.address.country"
                  data-testid="shipping.address.country"
                  label={t(`${tPrefix}.address.country`)}
                />
                <FormInput
                  type="text"
                  mask="11111-111"
                  maskChar={null}
                  name="shipping.address.zipcode"
                  data-testid="shipping.address.zipcode"
                  label={t(`${tPrefix}.address.zipcode`)}
                  onChange={e => setShippingCep(onChangeCep(e))}
                  disabled={shippingCep?.loading}
                />
                <FormInput
                  type="text"
                  name="shipping.address.city"
                  data-testid="shipping.address.city"
                  label={t(`${tPrefix}.address.city`)}
                  disabled={shippingCep?.loading}
                />
                <FormInput
                  className={styles.uppercase}
                  type="text"
                  name="shipping.address.state"
                  data-testid="shipping.address.state"
                  maxLength="2"
                  label={t(`${tPrefix}.address.state`)}
                  disabled={shippingCep?.loading}
                />
                <FormInput
                  type="text"
                  name="shipping.address.street"
                  data-testid="shipping.address.street"
                  label={t(`${tPrefix}.address.street`)}
                  disabled={shippingCep?.loading}
                />
                <FormInput
                  type="text"
                  name="shipping.address.neighborhood"
                  data-testid="shipping.address.neighborhood"
                  label={t(`${tPrefix}.address.neighborhood`)}
                  disabled={shippingCep?.loading}
                />
                <FormInput
                  type="text"
                  name="shipping.address.street_number"
                  data-testid="shipping.address.street_number"
                  label={t(`${tPrefix}.address.number`)}
                />
                <FormInput
                  type="text"
                  name="shipping.address.complementary"
                  data-testid="shipping.address.complementary"
                  label={t(`${tPrefix}.address.complementary`)}
                />
              </div>
            </>
          )}
        </CardContent>
        <CardActions>
          <Button onClick={onBack} fill="outline">
            {t(`${tPrefix}.back`)}
          </Button>
          <Button type="submit" data-testid="button-advance" disabled={isInvalidForm} loading={billingCep?.loading}>
            {t(`${tPrefix}.submit`)}
          </Button>
        </CardActions>
      </Form>
    </Card>
  )
}

PaymentForm.propTypes = {
  amount: PropTypes.number,
  billing: PropTypes.shape({
    address: PropTypes.shape({
      city: PropTypes.string,
      complementary: PropTypes.string,
      country: PropTypes.string,
      neighborhood: PropTypes.string,
      state: PropTypes.string,
      street: PropTypes.string,
      street_number: PropTypes.string,
      zipcode: PropTypes.string,
    }),
    name: PropTypes.string,
  }),
  hasPix: PropTypes.bool,
  onBack: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  payment: PropTypes.shape({
    boleto_expiration_date: PropTypes.string,
    card_cvv: PropTypes.string,
    card_expiration_date: PropTypes.string,
    card_holder_name: PropTypes.string,
    card_number: PropTypes.string,
    installments: PropTypes.number,
    payment_method: PropTypes.string,
    pix_expiration_date: PropTypes.string,
    plan_id: PropTypes.number,
  }),
  plans: PropTypes.arrayOf(
    PropTypes.shape({
      amount: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      paymentMethods: PropTypes.arrayOf(PropTypes.string.isRequired),
      value: PropTypes.string.isRequired,
    })
  ),
  shipping: PropTypes.shape({
    address: PropTypes.shape({
      city: PropTypes.string,
      complementary: PropTypes.string,
      country: PropTypes.string,
      neighborhood: PropTypes.string,
      state: PropTypes.string,
      street: PropTypes.string,
      street_number: PropTypes.string,
      zipcode: PropTypes.string,
    }),
    delivery_date: PropTypes.string,
    expedited: PropTypes.bool,
    fee: PropTypes.number,
    name: PropTypes.string,
  }),
  t: PropTypes.func.isRequired,
  type: PropTypes.string,
}

PaymentForm.defaultProps = {
  amount: null,
  billing: {
    address: {
      city: '',
      complementary: '',
      country: 'br',
      neighborhood: '',
      state: '',
      street: '',
      street_number: '',
      zipcode: '',
    },
    name: '',
  },
  hasPix: false,
  payment: {
    boleto_expiration_date: '',
    card_cvv: '',
    card_expiration_date: '',
    card_holder_name: '',
    card_number: '',
    installments: 1,
    payment_method: 'credit_card',
    pix_expiration_date: '',
    plan_id: null,
  },
  plans: [],
  shipping: {
    address: {
      city: '',
      complementary: '',
      country: 'br',
      neighborhood: '',
      state: '',
      street: '',
      street_number: '',
      zipcode: '',
    },
    delivery_date: '',
    expedited: false,
    fee: 0,
    name: '',
  },
  type: 'transaction',
}

export default PaymentForm
