import { useEffect, useState } from 'react';
export function useCalculateNumberOfColumns(children, fixedCols) {
  const [cols, setCols] = useState(0);
  useEffect(() => {
    if (Array.isArray(children)) {
      const colsValue = fixedCols !== null && fixedCols !== void 0 ? fixedCols : Math.floor(12 / children.length);
      setCols(colsValue);
    }
  }, [cols, setCols]);
  return {
    cols
  };
}