import {
  Button,
  DropdownMenu,
  DropdownMenuHeader,
  DropdownMenuItem,
} from '@stone-payments/jade'
import React from 'react'
import PropTypes from 'prop-types'

export const getExportOptions = onExport => [
  {
    action: () => onExport('csv'),
    title: 'CSV',
  },
  {
    action: () => onExport('xls'),
    title: 'Excel',
  },
]

const ExportData = ({
  buttonClick, disabled, loading, onExport, t,
}) => (
  <DropdownMenu
    trigger={(
      <Button
        size="medium"
        icon="action-download"
        disabled={disabled || loading}
        loading={loading}
        data-testid={disabled ? 'export-data-disabled' : 'export-data'}
        onClick={buttonClick && buttonClick}
      >
        {t('export_table')}
      </Button>
    )}
  >
    <DropdownMenuHeader title={t('export_to')} />
    <DropdownMenuItem
      label="CSV"
      disabled={disabled || loading}
      onClick={() => onExport('csv')}
    />
    <DropdownMenuItem
      label="Excel"
      disabled={disabled || loading}
      onClick={() => onExport('xls')}
    />
  </DropdownMenu>
)

ExportData.propTypes = {
  buttonClick: PropTypes.func,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  onExport: PropTypes.func,
  t: PropTypes.func.isRequired,
}

ExportData.defaultProps = {
  buttonClick: undefined,
  disabled: false,
  loading: false,
  onExport: () => null,
}

export default ExportData
