import isRequired from '../../validation/required'
import isNumber from '../../validation/number'
import isMinLength from '../../validation/minLength'
import isDate from '../../validation/date'
import isMinDate from '../../validation/minDate'

export default function ({ hasShipping, t, tPrefix }) {
  let validations = {
    'billing.address.city': isRequired(t(`${tPrefix}.address.validations.city.required`)),
    'billing.address.country': isRequired(t(`${tPrefix}.address.validations.country.required`)),
    'billing.address.neighborhood': isRequired(t(`${tPrefix}.address.validations.neighborhood.required`)),
    'billing.address.state': [
      isRequired(t(`${tPrefix}.address.validations.state.required`)),
      isMinLength(2, t(`${tPrefix}.address.validations.state.min_length`, { length: 2 })),
    ],
    'billing.address.street': isRequired(t(`${tPrefix}.address.validations.street.required`)),
    'billing.address.street_number': isRequired(t(`${tPrefix}.address.validations.number.required`)),
    'billing.address.zipcode': [
      isRequired(t(`${tPrefix}.address.validations.zipcode.required`)),
    ],
    'billing.name': isRequired(t(`${tPrefix}.billing.validations.name.required`)),
    boleto_expiration_date: [
      isRequired(t(`${tPrefix}.payment.validations.boleto_expiration_date.required`)),
      isDate(t(`${tPrefix}.payment.validations.boleto_expiration_date.date`)),
      isMinDate('DD/MM/YYYY', t(`${tPrefix}.payment.validations.boleto_expiration_date.min_date`)),
    ],
    card_cvv: [
      isRequired(t(`${tPrefix}.payment.validations.card_cvv.required`)),
      isMinLength(3, t(`${tPrefix}.payment.validations.card_cvv.min_length`, { length: 3 })),
    ],
    card_expiration_date: [
      isRequired(t(`${tPrefix}.payment.validations.card_expiration_date.required`)),
      isMinDate('MM/YY', t(`${tPrefix}.payment.validations.card_expiration_date.min_date`)),
    ],
    card_holder_name: [
      isRequired(t(`${tPrefix}.payment.validations.card_holder_name.required`)),
      isMinLength(3, t(`${tPrefix}.payment.validations.card_holder_name.min_length`, { length: 3 })),
    ],
    card_number: [
      isRequired(t(`${tPrefix}.payment.validations.card_number.required`)),
      isMinLength(13, t(`${tPrefix}.payment.validations.card_number.min_length`, { length: 13 })),
    ],
    pix_expiration_date: [
      isRequired(t(`${tPrefix}.payment.validations.pix_expiration_date.required`)),
      isDate(t(`${tPrefix}.payment.validations.pix_expiration_date.date`)),
      isMinDate('DD/MM/YYYY', t(`${tPrefix}.payment.validations.pix_expiration_date.min_date`)),
    ],
    plan_id: [
      isRequired(t(`${tPrefix}.validations.plan_id.required`)),
    ],
  }

  if (hasShipping) {
    validations = {
      ...validations,
      'shipping.address.city': isRequired(t(`${tPrefix}.address.validations.city.required`)),
      'shipping.address.country': isRequired(t(`${tPrefix}.address.validations.country.required`)),
      'shipping.address.neighborhood': isRequired(t(`${tPrefix}.address.validations.neighborhood.required`)),
      'shipping.address.state': [
        isRequired(t(`${tPrefix}.address.validations.state.min_length`)),
        isMinLength(2, t(`${tPrefix}.address.validations.state.min_length`, { length: 2 })),
      ],
      'shipping.address.street': isRequired(t(`${tPrefix}.address.validations.street.required`)),
      'shipping.address.street_number': isRequired(t(`${tPrefix}.address.validations.number.required`)),
      'shipping.address.zipcode': [
        isRequired(t(`${tPrefix}.address.validations.zipcode.required`)),
      ],
      'shipping.delivery_date': [
        isDate(t(`${tPrefix}.shipping.validations.delivery_date.date`)),
        isMinDate('DD/MM/YYYY', t(`${tPrefix}.shipping.validations.delivery_date.min_date`)),
      ],
      'shipping.fee': [
        isRequired(t(`${tPrefix}.shipping.validations.fee.required`)),
        isNumber(t(`${tPrefix}.shipping.validations.fee.required`)),
      ],
      'shipping.name': [
        isRequired(t(`${tPrefix}.shipping.validations.name.required`)),
      ],
    }
  }

  return validations
}
