import { createStitches } from "@stitches/react";
const spaceBase = 8;
export const {
  styled,
  css,
  globalCss,
  theme
} = createStitches({
  theme: {
    color: {
      black: "#000000",
      white: "#ffffff",
      barneyPurple20: "#dfd5ff",
      barneyPurple50: "#8267d3",
      barneyPurple100: "#6045af",
      barneyPurple200: "#3f2787",
      bethPink20: "#fbe5ec",
      bethPink50: "#f55d8b",
      bethPink100: "#df285f",
      bethPink200: "#a71a44",
      birdpersonGray500: "#454550",
      birdpersonGray600: "#30313c",
      birdpersonGray700: "#1b1c29",
      birdpersonGray800: "#070817",
      mortyYellow50: "#f8cc43",
      mortyYellow100: "#f3b700",
      mrmeeseksBlue20: "#e2efff",
      mrmeeseksBlue50: "#5691d6",
      mrmeeseksBlue100: "#4079bb",
      mrmeeseksBlue200: "#235894",
      picklerickGreen20: "#ecffce",
      picklerickGreen50: "#7fcc00",
      picklerickGreen100: "#65a300",
      picklerickGreen200: "#4f7f00",
      snufflesWhite: "#ffffff",
      squanchyGray20: "#f2f2f3",
      squanchyGray50: "#cdcdd0",
      squanchyGray100: "#a8a8ad",
      squanchyGray200: "#8b8b92",
      squanchyGray300: "#72737a",
      squanchyGray400: "#595a63",
      summerOrange20: "#fae2d2",
      summerOrange50: "#f4893f",
      summerOrange100: "#ec6e16",
      summerOrange200: "#c25408",
      titleColor: "$birdpersonGray500",
      bodyColor: "$squanchyGray400",
      bodyLinkColor: "$barneyPurple100"
    },
    space: {
      large: "".concat(spaceBase * 4, "px"),
      medium: "".concat(spaceBase * 3, "px"),
      small: "".concat(spaceBase * 2, "px"),
      tiny: "".concat(spaceBase, "px")
    },
    zIndices: {
      calendarPickerZIndex: 0,
      dateSelectorSidebarZIndex: 1,
      dateSelectorZIndex: 10,
      headerZIndex: 100,
      inputZIndex: 1,
      modalOverlayZIndex: 10000,
      popoverZIndex: 5,
      sidebarZIndex: 1000,
      switchBulletZIndex: 1,
      textareaZIndex: 1,
      tooltipArrowZIndex: -1,
      tooltipZIndex: 5
    },
    radii: {
      blockquote: "2px",
      default: "8px"
    },
    font: {
      titleFontFamily: '"Lato", sans-serif',
      bodyFontFamily: '"Lato", sans-serif',
      titleFontStyle: "normal"
    },
    fontSizes: {
      headline: "30px",
      display1: "36px",
      display2: "48px",
      display3: "60px",
      display4: "72px",
      caption: "12px",
      body: "16px",
      small: "14px"
    },
    fontWeights: {
      bodyTextWeight: 500,
      titleFontWeight: 500,
      bold: "bold",
      normal: "normal"
    },
    borders: {
      blockquote: "5px solid $barneyPurple100"
    },
    letterSpacings: {
      large: "0.02em"
    }
  }
});