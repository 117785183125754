import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'former-kit'
import { Message, MessageActions } from '../Message'
import GenericErrorIcon from '../../pages/Errors/ApplicationError/Icon.svg'

import style from './style.css'

function MFEApplicationError ({ t }) {
  return (
    <div className={style.flexHorizontalBox}>
      <Message
        image={<GenericErrorIcon width={365} height={148} />}
        message={t('pages.error.application_message')}
        title={t('pages.error.application_title')}
      >
        <MessageActions>
          <Button
            // eslint-disable-next-line no-restricted-globals
            onClick={() => location.reload()}
          >
            {t('pages.error.page_refresh')}
          </Button>
        </MessageActions>
      </Message>
    </div>
  )
}

MFEApplicationError.propTypes = {
  t: PropTypes.func.isRequired,
}

export default MFEApplicationError
