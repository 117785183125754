import { css, theme } from "../../../theme";
export const SearchInputStyle = css({
  boxSizing: 'border-box',
  position: 'relative',
  width: '100%',
  minHeight: '34px'
});
export const SearchInputBoxStyle = css({
  boxSizing: 'border-box',
  backgroundColor: theme.color.white,
  border: "1px solid ".concat(theme.color.squanchyGray100),
  paddingTop: 0,
  paddingBottom: 0,
  paddingRight: theme.space.small,
  paddingLeft: theme.space.small,
  variants: {
    status: {
      expanded: {
        borderTopLeftRadius: theme.space.tiny,
        borderTopRightRadius: theme.space.tiny,
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
        borderBottom: '0'
      },
      collapsed: {
        borderRadius: theme.space.tiny
      }
    }
  }
});