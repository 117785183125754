const formatPhone = (phone) => {
  if (phone.startsWith('+55')) {
    return phone
      .replace(/(\+\d{2})(\d{2})(\d{4,5})(\d{4})/, '$1 ($2) $3-$4')
  }

  return phone
    .replace(/(\+\d{2})(\d+)/, '$1 $2')
}

export default formatPhone
