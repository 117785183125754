import React from 'react'
import PropTypes from 'prop-types'
import {
  Button, Modal, ModalActions, ModalContent, Spacing,
} from 'former-kit'
import IconClose from 'emblematic-icons/svg/ClearClose32.svg'
import styles from './styles.css'

const bankFields = ['account', 'account_digit', 'agency', 'agency_digit']

const BankingDataConfirmationModal = ({
  actualValues,
  bankName,
  expectedValues,
  isOpen,
  onClose,
  onConfirm,
  t,
}) => {
  const generateValuesMessage = values => Object.keys(values)
    .filter(field => bankFields.includes(field))
    .map(field => t(`pages.recipients.banking_data_confirmation_modal.fields.${field}`, {
      n: Array.isArray(values[field])
        ? values[field].join('-')
        : values[field],
    }))
    .join('; ')

  return (
    <Modal
      isOpen={isOpen}
    >
      <div className={styles.title}>
        <h2>
          <strong>{t('pages.recipients.banking_data_confirmation_modal.title')}</strong>
        </h2>

        <Button
          fill="clean"
          icon={<IconClose width={12} height={12} />}
          onClick={onClose}
          relevance="low"
          size="tiny"
        />
      </div>
      <ModalContent>
        <p className={styles.content}>
          <strong>{t('pages.recipients.banking_data_confirmation_modal.content', { banco: bankName })}</strong>
        </p>
        <p className={styles.details}>
          {t('pages.recipients.banking_data_confirmation_modal.expected_values', { texto: generateValuesMessage(expectedValues) })}
          <br />
          <br />
          {t('pages.recipients.banking_data_confirmation_modal.actual_values', { texto: generateValuesMessage(actualValues) })}
        </p>
        <p className={styles.confirmation}>
          <strong>
            {t('pages.recipients.banking_data_confirmation_modal.confirmation')}
          </strong>
        </p>
      </ModalContent>
      <ModalActions>
        <Spacing />
        <Button fill="outline" onClick={onClose}>
          {t('pages.recipients.banking_data_confirmation_modal.cancel')}
        </Button>
        <Button onClick={onConfirm}>
          {t('pages.recipients.banking_data_confirmation_modal.proceed')}
        </Button>
      </ModalActions>
    </Modal>
  )
}

BankingDataConfirmationModal.propTypes = {
  actualValues: PropTypes.shape({
    account: PropTypes.number,
    account_digit: PropTypes.number,
    agency: PropTypes.number,
    agency_digit: PropTypes.number,
  }).isRequired,
  bankName: PropTypes.string.isRequired,
  expectedValues: PropTypes.shape({
    account: PropTypes.number,
    account_digit: PropTypes.number,
    agency: PropTypes.number,
    agency_digit: PropTypes.number,
  }).isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
}

export default BankingDataConfirmationModal
