import React from 'react';
import { Property } from '../../Property/Property';
import { rowStyle } from './Row.style';
export const Row = _ref => {
  let {
    label,
    children
  } = _ref;
  return React.createElement("div", {
    className: rowStyle()
  }, React.createElement(Property, {
    title: label,
    value: children
  }));
};