import React, { useEffect } from 'react';
import ReactDatePicker, { registerLocale } from "react-datepicker";
import ptBr from 'date-fns/locale/pt-BR';
import { CalendarWrapper } from './Calendar.style';
export const Calendar = _ref => {
  let {
    endDate,
    startDate,
    onChangeDate,
    maxDate,
    minDate
  } = _ref;
  useEffect(() => {
    registerLocale('pt-BR', ptBr);
  }, [registerLocale, ptBr]);
  const renderDayContents = day => {
    return React.createElement("span", {
      className: "calendar-inner-day-content"
    }, day);
  };
  return React.createElement(CalendarWrapper, null, React.createElement(ReactDatePicker, {
    selected: startDate,
    onChange: onChangeDate,
    monthsShown: 2,
    startDate: startDate,
    endDate: endDate,
    minDate: minDate,
    maxDate: maxDate,
    selectsRange: true,
    inline: true,
    locale: "pt-BR",
    renderDayContents: renderDayContents
  }));
};