import React, { useCallback, useEffect } from 'react'
import useCountDown from 'react-countdown-hook'
import PropTypes from 'prop-types'

import OtpModal from '../../components/OtpModal'
import timerDelay from '../../models/otp/timerDelay'

const RequestOtp = (props) => {
  const [timer, { start }] = useCountDown()
  const {
    handleOtpConfirmation, isOpen,
  } = props

  useEffect(() => {
    if (isOpen) {
      start(timerDelay)
    }
  }, [isOpen, start])

  const onResendCode = useCallback(async () => {
    await handleOtpConfirmation()
    start(timerDelay)
  }, [start, handleOtpConfirmation])

  return (
    <OtpModal
      onResendCode={onResendCode}
      onConfirm={handleOtpConfirmation}
      timer={timer}
      {...props}
    />

  )
}

RequestOtp.propTypes = {
  handleOtpConfirmation: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  status: PropTypes.oneOf([
    'error',
    'success',
  ]),
  statusMessage: PropTypes.string,
  t: PropTypes.func.isRequired,
}

RequestOtp.defaultProps = {
  status: 'error',
  statusMessage: '',
}

export default RequestOtp
