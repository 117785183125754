import { css, theme } from '../../../../theme';
export const SearchInputPopoverStyle = css({
  position: 'absolute',
  top: '100%',
  left: 0,
  width: '100%',
  height: 'auto',
  maxHeight: '248px',
  background: theme.color.white,
  paddingTop: theme.space.tiny,
  paddingBottom: theme.space.tiny,
  paddingLeft: theme.space.small,
  paddingRight: theme.space.small,
  zIndex: 1001,
  boxSizing: 'border-box',
  border: "1px solid ".concat(theme.color.squanchyGray100),
  borderTop: 0,
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
  borderBottomRightRadius: theme.space.tiny,
  borderBottomLeftRadius: theme.space.tiny,
  overflow: 'auto'
});
export const SearchInputPopoverItemStyle = css({
  display: 'flex',
  width: '100%',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: theme.space.small,
  padding: theme.space.tiny,
  border: 0,
  outline: 0,
  background: 'transparent',
  transition: 'all .3s ease',
  cursor: 'pointer',
  boxSizing: 'border-box',
  borderRadius: '4px',
  '&:hover': {
    backgroundColor: theme.color.squanchyGray50
  },
  '&:focus': {
    backgroundColor: theme.color.squanchyGray50
  },
  span: {
    fontFamily: theme.font.bodyFontFamily,
    fontWeight: 400,
    fontSize: theme.fontSizes.body,
    lineHeight: 1.5,
    color: theme.color.birdpersonGray500
  },
  '.big-text': {
    display: 'block',
    textAlign: 'left',
    flexGrow: 1
  }
});
export const SearchInputPopoverEmptyStyle = css({
  display: 'flex',
  width: '100%',
  flexDirection: 'columns',
  justifyContent: 'center',
  textAlign: 'center',
  paddingTop: theme.space.small,
  paddingBottom: theme.space.small,
  paddingLeft: 0,
  paddingRight: 0
});