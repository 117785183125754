import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import formatCurrency from '../../formatters/currency'

import style from './style.css'

const formatDate = date => moment(date).format('DD/MM/YYYY')

const formatCardNumber = number => number.substring(number.length - 4).padStart(19, '**** **** **** ')

const Payment = ({
  amount,
  boleto_expiration_date: boletoExpirationDate,
  card_number: cardNumber,
  installments,
  method,
  pix_expiration_date: pixExpirationDate,
  t,
  tPrefix,
}) => (
  <div className={style.subcard}>
    <h3 className={style.subtitle}>{t(`${tPrefix}.payment.title`)}</h3>
    <div data-testid="details-payment-method" className={style.info}>
      <p>{t(`${tPrefix}.payment.method`)}</p>
      <p>{t(`${tPrefix}.payment.${method}`)}</p>
    </div>
    {method === 'credit_card' && (
      <>
        <div className={style.info}>
          <p>{t(`${tPrefix}.payment.card_number`)}</p>
          <p>{formatCardNumber(cardNumber)}</p>
        </div>
        {installments && (
          <div className={style.info}>
            <p>{t(`${tPrefix}.payment.installments`)}</p>
            <p>
              {t(`${tPrefix}.payment.installments_of`, {
                amount: formatCurrency(amount / installments),
                installments,
              })}
            </p>
          </div>
        )}
      </>
    )}
    {method === 'boleto' && boletoExpirationDate && (
      <>
        <div className={style.info}>
          <p>{t(`${tPrefix}.payment.valid_at`)}</p>
          <p>{formatDate(boletoExpirationDate)}</p>
        </div>
        <div className={style.info}>
          <p>{t(`${tPrefix}.payment.value`)}</p>
          <p>{formatCurrency(amount)}</p>
        </div>
      </>
    )}
    {method === 'pix' && (
      <>
        <div className={style.info}>
          <p>{t(`${tPrefix}.payment.valid_at`)}</p>
          <p>{formatDate(pixExpirationDate)}</p>
        </div>
        <div className={style.info}>
          <p>{t(`${tPrefix}.payment.value`)}</p>
          <p>{formatCurrency(amount)}</p>
        </div>
      </>
    )}
  </div>
)

Payment.propTypes = {
  amount: PropTypes.number.isRequired,
  boleto_expiration_date: PropTypes.string,
  card_number: PropTypes.string,
  installments: PropTypes.number,
  method: PropTypes.string.isRequired,
  pix_expiration_date: PropTypes.string,
  t: PropTypes.func.isRequired,
  tPrefix: PropTypes.string.isRequired,
}

Payment.defaultProps = {
  boleto_expiration_date: null,
  card_number: null,
  installments: null,
  pix_expiration_date: null,
}

export default Payment
