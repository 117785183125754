import React from "react";
import { SearchInputTag } from "../Tag/Tag";
import { SearchInputPopoverItemStyle, SearchInputPopoverStyle, SearchInputPopoverEmptyStyle } from "./Popover.style";
export const SearchInputPopover = _ref => {
  let {
    status,
    options,
    tFunc,
    onSelect
  } = _ref;
  return React.createElement(React.Fragment, null, status === "expanded" && React.createElement("div", {
    className: SearchInputPopoverStyle(),
    "data-testid": "search-input-popover"
  }, options.length > 0 && options.map(option => React.createElement("button", {
    key: option.key,
    className: SearchInputPopoverItemStyle(),
    onClick: () => onSelect(option),
    "data-testid": "search-input-popover-item-".concat(option.key)
  }, React.createElement(SearchInputTag, {
    value: option.tag
  }), React.createElement("span", {
    className: "big-text"
  }, option.label), React.createElement("span", null, option.placeholder))), options.length === 0 && React.createElement("div", {
    className: SearchInputPopoverEmptyStyle(),
    "data-testid": "search-input-empty"
  }, tFunc("search_input.empty_one"), " ", React.createElement("br", null), tFunc("search_input.empty_two"))));
};