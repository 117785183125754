import React from 'react'
import PropTypes from 'prop-types'

import formatCurrency from '../../formatters/currency'

import style from './style.css'

const Items = ({
  amount,
  items,
  t,
  tPrefix,
}) => (
  <div className={style.subcard}>
    <h3 className={style.subtitle}>{t(`${tPrefix}.items.title`)}</h3>
    {items.map((item, i) => (
      // eslint-disable-next-line react/no-array-index-key
      <div className={style.item} key={`${item.title}-${i}`}>
        <p>{item.title} (x{item.quantity})</p>
        <p>{formatCurrency(item.unit_price)}</p>
      </div>
    ))}
    <div className={style.total}>
      <p>{t(`${tPrefix}.items.total`)}</p>
      <p>{formatCurrency(amount)}</p>
    </div>
  </div>
)

Items.propTypes = {
  amount: PropTypes.number.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    quantity: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    unit_price: PropTypes.number.isRequired,
  })).isRequired,
  t: PropTypes.func.isRequired,
  tPrefix: PropTypes.string.isRequired,
}

export default Items

