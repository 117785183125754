import { css, theme } from "../../../../theme";
export const SearchInputTagStyle = css({
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: theme.space.tiny,
  paddingRight: theme.space.tiny,
  border: "1px solid ".concat(theme.color.squanchyGray100),
  borderRadius: '4px',
  fontFamily: theme.font.bodyFontFamily,
  fontWeight: theme.fontWeights.bold,
  fontSize: theme.fontSizes.body,
  lineHeight: 1.5,
  color: theme.color.squanchyGray400,
  boxSizing: 'border-box',
  display: 'flex',
  alignItems: 'center',
  gap: theme.space.small,
  div: {
    width: '16px',
    height: '16px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  },
  svg: {
    width: '16px',
    height: '16px'
  }
});