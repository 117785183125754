import {
  always,
  cond,
  propEq,
  T,
  uncurryN,
} from 'ramda'

const buildErrorsMessage = uncurryN(2, t => cond([
  [propEq('isFirstTime', true), always('')],
  [propEq('isResendCode', true), always(t('components.otp.resend_success'))],
  [T, always(t('components.otp.invalid_code'))],
]))

export default buildErrorsMessage
