import React from 'react'
import PropTypes from 'prop-types'
import IconClose from 'emblematic-icons/svg/ClearClose32.svg'
import { Modal, ModalTitle, ModalContent } from 'former-kit'

import OtpConfirmation from '../OtpConfirmation'

const OtpModal = ({
  isOpen,
  loading,
  onClose,
  onConfirm,
  onResendCode,
  status,
  statusMessage,
  t,
  timer,
}) => (
  <Modal isOpen={isOpen}>
    <ModalTitle
      title=""
      closeIcon={<IconClose width={16} height={16} data-testid="close-icon" />}
      onClose={onClose}
    />
    <ModalContent>
      <OtpConfirmation
        loading={loading}
        onConfirm={onConfirm}
        onResendCode={onResendCode}
        status={status}
        statusMessage={statusMessage}
        t={t}
        time={timer}
      />
    </ModalContent>
  </Modal>
)

OtpModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onResendCode: PropTypes.func.isRequired,
  status: PropTypes.oneOf([
    'error',
    'success',
  ]),
  statusMessage: PropTypes.string,
  t: PropTypes.func.isRequired,
  timer: PropTypes.number,
}

OtpModal.defaultProps = {
  loading: false,
  status: 'success',
  statusMessage: '',
  timer: 0,
}

export default OtpModal
