import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'former-kit'
import styles from './style.css'
import Logo from '../../components/Logo'

const Confirmation = ({
  environment,
  labels: {
    backToLogin,
    confirmation,
    confirmationEmphasis,
  },
  onBackToLogin,
  t,
}) => {
  const isLive = environment === 'live'

  return (
    <div className={styles.accountCard}>
      <div className={styles.logoWrapper}>
        <Logo test={environment === 'test'} alt={t('landing.logo')} />
      </div>
      <span className={styles.confirmationMessage}>
        <b>{confirmationEmphasis}</b>
        {' '}
        <span>{confirmation}</span>
      </span>
      <div className={styles.confirmationButton}>
        <Button
          fullWidth
          onClick={onBackToLogin}
          size="huge"
          relevance={isLive ? 'normal' : 'high'}
        >
          {backToLogin}
        </Button>
      </div>
    </div>
  )
}

Confirmation.propTypes = {
  environment: PropTypes.oneOf(['live', 'test']).isRequired,
  labels: PropTypes.shape({
    backToLogin: PropTypes.string.isRequired,
    confirmation: PropTypes.string.isRequired,
    confirmationEmphasis: PropTypes.string.isRequired,
  }).isRequired,
  onBackToLogin: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
}

export default Confirmation
