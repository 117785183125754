import React from 'react'
import PropTypes from 'prop-types'

import formatCpfCnpj from '../../formatters/cpfCnpj'
import formatPhone from '../../formatters/phone'

import style from './style.css'

const Customer = ({
  document,
  documentType,
  email,
  name,
  phone,
  t,
  tPrefix,
}) => (
  <div className={style.subcard}>
    <h3 className={style.subtitle}>{t(`${tPrefix}.customer.title`)}</h3>
    <div className={style.info}>
      <p>{t(`${tPrefix}.customer.name`)}</p>
      <p>{name}</p>
    </div>
    <div className={style.info}>
      <p>{t(`${tPrefix}.customer.${documentType}`)}</p>
      <p>{formatCpfCnpj(document)}</p>
    </div>
    <div className={style.info}>
      <p>{t(`${tPrefix}.customer.email`)}</p>
      <p>{email}</p>
    </div>
    <div className={style.info}>
      <p>{t(`${tPrefix}.customer.phone`)}</p>
      <p>{formatPhone(phone)}</p>
    </div>
  </div>
)

Customer.propTypes = {
  document: PropTypes.string.isRequired,
  documentType: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  tPrefix: PropTypes.string.isRequired,
}

export default Customer
